import Cookie from "../vendors/cookie"

const module = {}
let $cookieContainer
let $closeButton
let $acceptButton
let $extensionToggler
let $statisticsCheckbox
let $extension
const cookieName = "cookie-policy"

const closePopup = function () {
  $cookieContainer.removeClass("visible")
  $.publish("cookie:hidden")
}

const closeAndSaveState = function () {
    closePopup()
    Cookie.set(cookieName, 1, 365, "/")
		if(!$statisticsCheckbox.is(':checked')) {
			window.jsCookies.set('tracking-policy', 1, 365, '/')
			window.dataLayer.push({
				event : 'blacklist-update',
				restrictionLevel: 'block everything',
				'gtm.blacklist': ['customScripts', 'customPixels', 'ga', 'opt', 'ua', 'gcs','gclidw','ts','flc','fls','sp','awct','adm', 'fr', 'datr']
			})
		}
}

const toggleExtension = function() {
    $extensionToggler.toggleClass('show-less-state')
    $extension.toggle()
}

const attachEvents = function () {
  $closeButton.on("click", function (e) {
    e.preventDefault()
    closePopup()
  })
  $acceptButton.on("click", function (e) {
    e.preventDefault()
    closeAndSaveState()
  })
	$extensionToggler.on('click', function(e) {
		e.preventDefault()
		toggleExtension()
	})
}

module.init = function () {
  $cookieContainer = $(".js-cookie")
  $closeButton = $cookieContainer.find(".js-close")
  $acceptButton = $cookieContainer.find(".js-accept")
  $statisticsCheckbox = $cookieContainer.find('#statistics')
  $extensionToggler = $cookieContainer.find('.js-cookie-open-extensions')
  $extension = $cookieContainer.find('.js-cookie-extensions')
  attachEvents()

  if (!Cookie.check(cookieName)) {
    $cookieContainer.addClass("visible")
  } else {
    closePopup()
  }
}

export default module
