import './js/vendors'
import youtubeLoader from './js/helpers/youtube-loader'
import utilities from './js/components/util'
import responsive from './js/components/responsive'
import navigation from './js/components/navigation'
import countrySelector from './js/components/country-selector'
import squareHelper from './js/components/square-helper'
import emailSignup from './js/components/email-signup'
import teaserTabs from './js/components/teaser-tabs'
import equalHeight from './js/components/equal-height'
import header from './js/components/header'
import showRecipe from './js/components/show-recipe'
import recipeSearch from './js/components/recipe-search'
import cookiePolicy from './js/components/cookie'
import browserAnnouncement from './js/components/browser-announcement'
import newsSearch from './js/components/news-search'
import productSearch from './js/components/product-search'
import nutritionToggler from './js/components/nutrition-toggler'
import productRelatedProducts from './js/components/product-related-products'
import instagram from './js/components/instagram'
import speechBubble from './js/components/speech-bubble'
import meetBloggers from './js/components/meet-bloggers'
import showMoreItems from './js/components/show-more-items'
import bloggerCollapse from './js/components/blogger'
import videoBlock from './js/components/video-block'
import videoCampaignBanner from './js/components/video-campaign-banner'
import videoGalleryBlock from './js/components/video-gallery'
import imageGalleryBlock from './js/components/image-gallery'
import recipeVideo from './js/components/recipe-video'
import tacoPage from './js/components/taco-page'
import competitionBlock from './js/components/competition-block'
import surpriseCampaign from './js/components/surprise-campaign'

import './styl/main.styl'

function requireAll(r) {
  r.keys().forEach(r)
}
requireAll(require.context('./svg/', true, /\.svg$/))

const initModules = () => {
	youtubeLoader.loadYoutubePlayer()
  utilities.init()
  responsive.init()
  navigation.init()
  countrySelector.init()
  squareHelper.init()
  emailSignup.init()
  teaserTabs.init()
  equalHeight.init('.js-equal-height')
  header.init()
  showRecipe.init()
  recipeSearch.init()
  cookiePolicy.init()
  browserAnnouncement.init()
  newsSearch.init()
  productSearch.init()
  nutritionToggler.init()
  productRelatedProducts.init()
  instagram.init()
  speechBubble.init()
  meetBloggers.init()
  showMoreItems.init()
  bloggerCollapse.init()
  imageGalleryBlock.init()
  tacoPage.init()
  competitionBlock.init()
  surpriseCampaign.init()
  videoBlock.init()
  videoGalleryBlock.init()
  videoCampaignBanner.init()
  recipeVideo.init()
}

const initSliders = () => {
  const $slider = $('.related-products__carousel')
  const slidesLen = $slider.children().length

  if (slidesLen > 1) {
    $slider.owlCarousel({
      items: 1,
      loop: true,
      nav: true
    })
  } else {
    $slider.show()
  }
}

const initFormValidation = () => {
  $('form').h5Validate({
    click: false
  })
}

$(document).ready(() => {
  initModules()
  initSliders()
  initFormValidation()
})
