/* eslint-disable */

import transitionEnd from '../helpers/transition-end'

function convertTime(duration) {
  const totalSec = Math.round(duration)
  const hours = parseInt(totalSec / 3600) % 24
  const minutes = parseInt(totalSec / 60) % 60
  const seconds = totalSec % 60
  const result = `${(hours > 0 ? `${hours < 10 ? `0${  hours}` : hours  }:` : "") + (minutes < 10 ? `0${  minutes}` : minutes)  }:${  seconds < 10 ? `0${  seconds}` : seconds}`
  return result
}

const VideoBlock = function (container) {
  const module = {}
  const $container = $(container)
  let $button
  let $modal
  let $modalOverlay
  let savedVolumeLevel
  let $volumeBar
  let $volumeBarContainer
  let $muteButton
  let player
  let $timeline
  let $currentTime
  let timer
  let $timelineContainer
  let $playButton
  let totalDuration
  let $pauseButton
  let $box
  let $videoControl
  let $playerOverlay
  let mousemoveTimer

  const closeModal = function () {
    $modalOverlay.off("click")
    $(document).off("keydown.overlay")
    player.pauseVideo()
    $modal.removeClass("visible")
  }

  const attachModalEvents = function () {
    $modalOverlay.on("click", function (e) {
      e.preventDefault()
      closeModal()
    })
    $(document).on("keydown.overlay", function (e) {
      if (e.which == "27") {
        closeModal()
      }
    })
  }

  const onPlayerReady = function (event) {
    event.target.playVideo()
    setupPlayer(event.target)
  }

  const onStateChange = function (event) {
    if (event.data == YT.PlayerState.PLAYING) {
      runTimer()
    } else {
      clearInterval(timer)
    }
  }

  const updateTimeline = function (current, duration) {
    $timeline.css({
      transform: `translate3d(${  parseFloat(current / duration) * 100  }%, 0,0)`
    })
  }

  const seekToSeconds = function (time) {
    if (player) {
      player.seekTo(time)
      updateTimeline(time, totalDuration)
    }
  }

  const setVolume = function (level) {
    if (player) {
      player.setVolume(parseInt(level))
      updateVolumeLine(parseInt(level))
    }
  }

  const updateVolumeLine = function (level) {
    $volumeBar.css({
      transform: `translate3d(${  level  }%, 0,0)`
    })
  }

   const updateCurrentTime = function (time) {
    $currentTime.text(convertTime(time))
  }

  const runTimer = function () {
    if (timer) {
      clearInterval(timer)
    }
    timer = setInterval(function () {
      updateCurrentTime(player.getCurrentTime())
      updateTimeline(player.getCurrentTime(), player.getDuration())
    }, 500)
  }

  const setupPlayer = function (data) {
    const duration = data.getDuration()
    const currentTime = data.getCurrentTime()
    const volume = data.getVolume()
    totalDuration = duration
    $modal.find(".js-total-time").text(convertTime(duration))
    updateCurrentTime(currentTime)
    updateTimeline(currentTime, duration)
    updateVolumeLine(volume)
  }

  const showModal = function (videoId) {
    $modal.one(transitionEnd, function () {
      if (window.youTubeIsReady) {
        if (!player) {
          setTimeout(function () {
            player = new YT.Player(`recipe-video-${  videoId}`, {
              height: "390",
              width: "640",
              playerVars: {
                "autoplay": 1,
                "controls": 0
              },
              videoId,
              events: {
                "onReady": onPlayerReady,
                "onStateChange": onStateChange
              }
            })
          }, 100)
        } else {
          player.playVideo()
        }
      }
    })
    $modal.addClass("visible")
    attachModalEvents()
  }

  const appendIframeForMobile = function (videoId) {
    const iframe = `<iframe class="video-iframe" src="//www.youtube-nocookie.com/embed/${  videoId  }?showinfo=0">`
    $(iframe).appendTo(`.mobile_video_inner--${  videoId}`)
  }

  const showVideo = function (videoId) {
    const iOS = !!navigator.platform && (/iPad|iPhone|iPod/).test(navigator.platform)
    if (window.currentMQ !== "small" && !iOS) {
      showModal(videoId)
    } else {
      appendIframeForMobile(videoId)
    }
  }

  const watchMouseMove = function () {
    if (mousemoveTimer) {
      clearTimeout(mousemoveTimer)
    }
    mousemoveTimer = setTimeout(function () {
      $videoControl.addClass("hidden")
      $playerOverlay.addClass("visible")
    }, 2000)
  }

  const attachEvents = function () {
    let buttonIsClicked = false
    $button.on("click", function (e) {
      e.preventDefault()
      const videoId = $button.data("id")
      showVideo(videoId)
      if (!buttonIsClicked) {
        buttonIsClicked = true
        $button.addClass("clicked")
      }
    })
    $playButton.on("click", function (e) {
      e.preventDefault()
      if (player) {
        player.playVideo()
      }
    })
    $pauseButton.on("click", function (e) {
      e.preventDefault()
      if (player) {
        player.pauseVideo()
      }
    })
    $timelineContainer.on("click", function (e) {
      e.preventDefault()
      const width = $timelineContainer.width()
      const offset = e.offsetX / width
      seekToSeconds(totalDuration * offset)
    })
    $volumeBarContainer.on("click", function (e) {
      e.preventDefault()
      const width = $volumeBarContainer.width()
      const offset = e.offsetX / width
      setVolume(offset * 100)
    })
    $muteButton.on("click", function (e) {
      e.preventDefault()
      if ($muteButton.hasClass("muted")) {
        $muteButton.removeClass("muted")
        setVolume(savedVolumeLevel || 100)
      } else {
        savedVolumeLevel = player.getVolume()
        $muteButton.addClass("muted")
        setVolume(0)
      }
    })
    $box.on("mouseleave", function (e) {
      $videoControl.addClass("hidden")
      $playerOverlay.removeClass("visible")
      if (mousemoveTimer) {
        clearTimeout(mousemoveTimer)
      }
    })
    $box.on("mouseenter", function (e) {
      $videoControl.removeClass("hidden")
      $playerOverlay.addClass("visible")
      watchMouseMove()
    })
    $box.on("mousemove", function (e) {
      $videoControl.removeClass("hidden")
      $playerOverlay.removeClass("visible")
      watchMouseMove()
    })
  }
  module.init = function (container) {
    if ($container.length) {
      $button = $container.find(".js-recipe-video-button")
      $box = $container.find(".js-recipe-modal-box")
      $modal = $container.find(".js-recipe-modal")
      $modalOverlay = $container.find(".js-recipe-overlay")
      $currentTime = $container.find(".js-current-time")
      $timeline = $container.find(".js-timeline")
      $pauseButton = $container.find(".js-pause")
      $playButton = $container.find(".js-play")
      $timelineContainer = $container.find(".js-timeline-container")
      $muteButton = $container.find(".js-mute")
      $volumeBarContainer = $container.find(".js-volume-bar-container")
      $volumeBar = $container.find(".js-volume-bar")
      $videoControl = $container.find(".js-video-control")
      $playerOverlay = $container.find(".js-player-overlay")
      attachEvents()
    }
  }

  return module
}

export default {
  init: () => {
    $('.js-recipe-video').each(function () {
      new VideoBlock(this).init()
    })
  }
}
