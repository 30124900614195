/* eslint-disable */

const module = {}
let $form
let $openPopup
let $popup
let $closePopup
let $popupFader
let $anchorLink
let $voteBtn
let $voteItemParent
let $shareBtn
let language
let teamType
let FacebookId
let AccessToken
let TeamBlockId

const signUp = function () {
  const uploadURL = `/api/${  language  }/taco/upload`
  const submitURL = `/api/${  language  }/taco/register`
  let defaultText
  let Attachments = []
  $form.find("input[type=\"file\"]").on("change", function () {
    const el = $(this)
    const extension = el.val()
    const fileName = el.val().split("\\").pop()
    switch (extension.substring(extension.lastIndexOf(".") + 1).toLowerCase()) {
      case "gif":
      case "jpg":
      case "jpeg":
      case "png":
        el.addClass("file-attached")
        el.parent().addClass("file-attached")
        defaultText = el.next("p").html()
        el.next("p").text(fileName)
        el.parents(".form-row").next().removeClass("hidden")
        break
      default:
        alert("Uploaded file not an image")
        break
    }
  })
  $form.find(".js-clear-upload").on("click", function () {
    const btnParent = $(this).parent()
    btnParent.find("p").html(defaultText)
    btnParent.find("input[type=\"file\"]").val("").removeClass("file-attached")
    btnParent.removeClass("file-attached")
    $(this).parents(".form-row").next().addClass("hidden")
  })
  $form.on("submit", function (e) {
    e.preventDefault()
    const isValid = $form.h5Validate("allValid"); const data = {}; const formValuesArray = $form.serializeArray()
    for (let i = 0, len = formValuesArray.length; i < len; i++) {
      data[formValuesArray[i].name] = formValuesArray[i].value
    }
    if (!isValid) {
      return
    }
    const formData = new FormData()
    formData.append("action", "uploadImages")
    $.each($("input[type=file]"), function (i, obj) {
      $.each(obj.files, function (j, file) {
        formData.append(`photo[${  j  }]`, file)
      })
    })
    $form.parent().append("<div class=\"loading-mask\"><div class=\"sk-folding-cube\"><div class=\"sk-cube1 sk-cube\"></div><div class=\"sk-cube2 sk-cube\"></div><div class=\"sk-cube4 sk-cube\"></div><div class=\"sk-cube3 sk-cube\"></div></div></div>")
    $.ajax({
      url: uploadURL,
      type: "POST",
      data: formData,
      contentType: false,
      processData: false,
      success (response) {
        Attachments = []
        Attachments.push(response)
        data.Attachments = Attachments[0]
        $.ajax({
          url: submitURL,
          type: "POST",
          data: JSON.stringify(data),
          contentType: "application/json",
          success (response) {
            console.log(response)
            const $block = $form.parent()
            $block.find(".loading-mask").remove()
            $form.remove()
            $block.find("h3:not(.signup-confirmation)").hide()
            $block.find(".signup-confirmation").removeClass("hidden")
          },
          error () {
            const $block = $form.parent()
            $block.find(".loading-mask").remove()
            $form.remove()
            $block.find("h3:not(.signup-error)").hide()
            $block.find(".signup-error").removeClass("hidden")
          }
        })
      },
      error () {
        console.log("error")
      }
    })
  })
}

const popup = function () {
  $openPopup.on("click", function (e) {
    e.preventDefault()
    $("html, body").animate({
      scrollTop: $(".taco-signup").offset().top
    }, 300, function () {
      $popupFader.fadeIn(300, function () {
        $popup.fadeIn()
      })
    })
  })
  $closePopup.on("click", function (e) {
    e.preventDefault()
    $form.find("input[type=\"checkbox\"]").prop("checked", "checked").removeClass("ui-state-error")
    $popup.fadeOut(300, function () {
      $popupFader.fadeOut(300)
    })
  })
}

const pageScrolling = function () {
  $anchorLink.on("click", function (e) {
    e.preventDefault()
    const section = $(this).attr("href")
    if (!$(section).length) {
      return
    }
    $("html, body").animate({
      scrollTop: $(section).offset().top
    }, 300)
  })
}

const voteTaco = function () {
  const voteURL = `/api/${  language  }/taco/vote/`
  let $btn
  const data = {}
  $voteBtn = $(".js-vote")
  $shareBtn = $(".js-share-vote")
  const votedText = $voteBtn.parent().find("input[type=\"hidden\"]").val()

  if (window.fbEnsureInit) {
    window.fbEnsureInit(function () {
      checkStatus()
    })
  }

  $voteBtn.on("click", function (e) {
    e.preventDefault()
    $btn = $(this)
    $voteItemParent = $(this).parents(".vote-item")
    TeamBlockId = $voteItemParent.data("team-id")
    teamType = $voteItemParent.parents(".js-vote-parent").data("team-type")

    function callApi() {
      data.FacebookId = FacebookId
      data.TeamBlockId = TeamBlockId
      data.TeamType = teamType
      data.AccessToken = AccessToken
      $.ajax({
        url: voteURL,
        type: "POST",
        data: JSON.stringify(data),
        contentType: "application/json",
        success () {
          $btn.parent().addClass("voted-item")
          let newAmount = parseInt($btn.parent().find(".js-votes-score").text())
          newAmount+=1
          $btn.parents(".js-vote-parent").addClass("disable-voting").find(".js-vote").text(votedText)
        },
        error () {
          alert("You already voted!")
          checkStatus()
        }
      })
    }

    FB.getLoginStatus(function (response) {
      if (response.status != "connected") {
        FB.login(function (response) {
          FacebookId = response.authResponse.userID
          AccessToken = response.authResponse.accessToken
          callApi()
        })
      } else {
        FacebookId = response.authResponse.userID
        AccessToken = response.authResponse.accessToken
        callApi()
      }
    })
  })

  function checkStatus() {
    FB.getLoginStatus(function (response) {
      if (response.status == "connected") {
        FacebookId = response.authResponse.userID
        AccessToken = response.authResponse.accessToken
        $.ajax({
          url: `/api/${  language  }/taco/votes/facebookId/${  FacebookId  }`,
          type: "GET",
          success (response) {
            for (let i = 0; i < response.length; i++) {
              $(`[data-team-type="${  response[i].TeamType  }"]`).addClass("disable-voting").find(".js-vote").text(votedText)
              $(`[data-team-id="${  response[i].TeamBlockId  }"]`).addClass("voted-item")
            }
          }
        })
      }
    })
  }

  function highlightVotedBlock() {
    const url = window.location.href; const votedItem = url.split("team-id=").pop().split("#").shift()
    $(`[data-team-id="${  votedItem  }"]`).parent().addClass("highlited-item")
  }

  function shareVote() {
    $shareBtn.on("click", function (e) {
      e.preventDefault()
      const el = $(this)
      FB.ui({
        method: "share",
        display: "popup",
        href: el.attr("href"),
        quote: "Takk for at du stemte! Del på Facebook for å sikre enda flere stemmer til bidraget."
      }, function (response) {})
    })
  }
  shareVote()
  highlightVotedBlock()
}

const attachEvents = function () {
  signUp()
  popup()
  pageScrolling()
  voteTaco()
  $.ajax({
    url: `/api/${  language  }/taco/votes/${  $(".taco-wrapper-page").attr("id")  }`,
    type: "GET",
    success (response) {
      $(".vote-item").parent().attr("data-votes", 0)
      for (let i = 0; i < response.length; i++) {
        $(`[data-team-id="${  response[i].TeamId  }"]`).find(".js-votes-score").text(response[i].Count)
        $(`[data-team-id="${  response[i].TeamId  }"]`).find(".js-votes-score").parents(".vote-item").parent().attr("data-votes", response[i].Count)
      }
      const $consumer = $(".vote-consumer .votes-row>div"); const $consumerItems = $consumer.children("div");
      [].sort.call($consumerItems, function (a, b) {
        return +$(b).attr("data-votes") - +$(a).attr("data-votes")
      })
      $consumerItems.each(function () {
        $consumer.append(this)
      })
      $consumerItems.each(function () {
        const el = $(this)
        el.find(".js-votes-score").text($(this).index() + 1)
        setTimeout(function () {
          el.animate({
            opacity: 1
          }, 500)
        }, 300)
      })
      const $restaurant = $(".vote-restaurant .votes-row>div"); const $restaurantItems = $restaurant.children("div");
      [].sort.call($restaurantItems, function (a, b) {
        return +$(b).attr("data-votes") - +$(a).attr("data-votes")
      })
      $restaurantItems.each(function () {
        $restaurant.append(this)
      })
      $restaurantItems.each(function () {
        const el = $(this)
        el.find(".js-votes-score").text($(this).index() + 1)
        setTimeout(function () {
          el.animate({
            opacity: 1
          }, 500)
        }, 300)
      })
    }
  })
}

module.init = function () {
  $form = $(".js-taco-signup")
  if (!$form.length) {
    return
  }
  $openPopup = $(".js-open-popup")
  $closePopup = $(".js-agree-btn")
  $popup = $(".js-info-popup")
  $popupFader = $(".js-popup-fader")
  $anchorLink = $(".anchor-link")
  language = $(".taco-wrapper-page").data("language")
  if (language) {
    attachEvents()
  }
}

export default module
