import Util from './util'

const module = {}

const setHeightForRow = function ($rowElements, targetHeight) {
  for (let index = 0; index < $rowElements.length; index+=1) {
    $rowElements[index].css("min-height", targetHeight)
  }
}

const treatRows = function ($elementsCollection) {
  let currentRowTallest = null 
    let currentRowTopPosition = null
    let $element
    const $rowElements = []
    let elementTopPosition = null

  $elementsCollection.each(function () {
    $element = $(this)
    elementTopPosition = $element.position().top
    $element.css("min-height", "")
    if (currentRowTopPosition != elementTopPosition) {
      setHeightForRow($rowElements, currentRowTallest)
      elementTopPosition = $element.position().top
      $rowElements.length = 0
      currentRowTopPosition = elementTopPosition
      currentRowTallest = parseInt($element.css("height"), 10)
    } else {
      currentRowTallest = currentRowTallest < parseInt($element.css("height"), 10) ? parseInt($element.css("height"), 10) : currentRowTallest
    }
    $rowElements.push($element)
  }).promise().done(function () {
    setHeightForRow($rowElements, currentRowTallest)
  })
}

const init = function (selector) {
  const $elementsForRowheightAdjustment = $(selector)
  const delayedInit = Util.debounce(function () {
    treatRows($elementsForRowheightAdjustment)
  }, 250)
  if ($elementsForRowheightAdjustment.length) {
    treatRows($elementsForRowheightAdjustment)
    $(window).on("resize", delayedInit)
  }
}

module.init = init
module.setHeightForRow = setHeightForRow

export default module
