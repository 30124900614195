/* eslint-disable */

import 'util'

const module = {}

module.init = function () {
  if (typeof unfoldWidgetSrc !== "undefined") {
    $.ajaxSetup({
      cache: true
    })
    $.getScript(unfoldWidgetSrc)
    $.ajaxSetup({
      cache: false
    })
  }
}

export default module
