/* eslint-disable */

import 'util'

const module = {}
let activeMQ

const mqSync = function () {
  if (window.opera) {
    activeMQ = window.getComputedStyle(document.body, ":after").getPropertyValue("content")
  } else window.getComputedStyle
  {
    activeMQ = window.getComputedStyle(document.head, null).getPropertyValue("font-family")
  }
  activeMQ = activeMQ.replace(/"/g, "")
  activeMQ = activeMQ.replace(/'/g, "")
  if (activeMQ !== window.currentMQ) {
    if (activeMQ === "small" || activeMQ === "medium" || activeMQ === "large" || activeMQ === "xlarge" || activeMQ === "xxlarge") {
      window.currentMQ = activeMQ
      $.publish("breakpoint_change", window.currentMQ)
    }
  }
}

const _updateBgImageCollection = function (props) {
  const $blocks = props.items
  const data_attribute_to_use = _getDataAttributeToUse(props.breakpoint)
  $blocks.each(function () {
    const item = $(this)
    const new_src_attr = item.attr(data_attribute_to_use)
    const existing_image_src = item.css("background-image")
    if (typeof new_src_attr !== "undefined" && new_src_attr !== existing_image_src) {
      item[0].style.backgroundImage = `url(${  new_src_attr  })`
    }
  })
}

var _getDataAttributeToUse = function (breakpoint) {
  let target_size
  let data_attribute_to_use
  const fallback_breakpoint = "large"
  target_size = breakpoint === "unknown" ? fallback_breakpoint : breakpoint
  data_attribute_to_use = `data-${  target_size}`
  return data_attribute_to_use
}

window.currentMQ = "unknown"

module.init = function () {
  mqSync()
  const $responsive_bg_blocks = $(".js-responsive-bg")
  $(window).smartresize(function () {
    mqSync()
  })
  _updateBgImageCollection({
    items: $responsive_bg_blocks,
    breakpoint: window.currentMQ
  })
  $.subscribe("breakpoint_change", function (e, breakpoint) {
    _updateBgImageCollection({
      items: $responsive_bg_blocks,
      breakpoint
    })
  })
}

module.updateBackground = function ($items) {
  _updateBgImageCollection({
    items: $items,
    breakpoint: window.currentMQ
  })
}

export default module
