import SearchFilter from './search-filter'

const module = {}

module.init = function () {
  const searchInstance = new SearchFilter()
  searchInstance.init({
    containerSelector: '.js-news-search',
    typeInApi: 'news'
  })
}

export default module
