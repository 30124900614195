const module = {}
let $body
let $buttonOpen
let $countrySelect
let $countrySelectList
let opened = false

const closeMenu = function () {
  opened = false
  $body.removeClass("country-select-open")
  $body.off("click.country")
}

const openMenu = function () {
  if (opened) {
    closeMenu()
  } else {
    opened = true
    $body.addClass("country-select-open")
    $body.on("click.country", function (e) {
      if (opened && e.target != $buttonOpen[0] && e.target !== $countrySelectList[0]) {
        if (!$(e.target).parents(".js-country-list").length) {
          closeMenu()
        }
      }
    })
  }
}

const attachEvents = function () {
  $buttonOpen.on("click", function (e) {
    e.preventDefault()
    openMenu()
  })
  if ((/iPhone|iPad|iPod/i).test(navigator.userAgent)) {
    $(window).on("pageshow", function (e) {
      if (e.originalEvent.persisted) {
        closeMenu()
      }
    })
  }
}

module.init = function () {
  $body = $("body")
  $buttonOpen = $(".js-country-selector-button")
  $countrySelect = $(".js-country-selector")
  $countrySelectList = $countrySelect.find(".js-country-list")
  attachEvents()
}

export default module
