const module = {}
let $container

const attachEvents = function () {
  $container.each(function () {
    const $this = $(this); const $button = $this.find(".js-button")
    $button.on("click", function (e) {
      if (window.currrentMQ !== "small" || window.currrentMQ !== "medium") {
        e.preventDefault()
        $this.toggleClass("opened")
      }
    })
  })
}

module.init = function () {
  $container = $(".js-blogger-collapse")
  if (!$container.length) {
    return
  }
  attachEvents()
}

export default module
