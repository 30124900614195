const module = {}
let $textContainer
let $bubbles
let previousIsMobile = false
const MIN_CHARS = 12
const LINE_HEIGHT = 23
const CHAR_WIDTH = 6.531468531468532
const ASPECT_THRESHOLD = 1.7

function getClassName(chars) {
  if (chars > 130) {
    return "large show"
  }
  if (chars > 100) {
    return "medium show"
  }
  return "show"
}

function setProportionalWidth() {
  $.each($bubbles, function (index, val) {
    const $thisBubble = $(val)
    const $text = $thisBubble.find(".js-speech-bubble-text")
    const chars = $text.text().length
    const width = chars * CHAR_WIDTH
    const temp = Math.sqrt(LINE_HEIGHT * width / ASPECT_THRESHOLD)
    if (chars < MIN_CHARS) {
      $thisBubble.addClass(getClassName(chars))
      return
    }
    $thisBubble.addClass(getClassName(chars))
    $text.width(temp * ASPECT_THRESHOLD)
  })
}

module.init = function () {
  $textContainer = $(".js-speech-bubble-text")
  $bubbles = $(".js-speech-bubble")
  if (!$bubbles.length) {
    return
  }
  previousIsMobile = window.currentMQ == "small" || window.currentMQ == "medium"
  if (!previousIsMobile) {
    setProportionalWidth()
  }
  $.subscribe("breakpoint_change", function (e, breakpoint) {
    if (breakpoint === "small" || breakpoint === "medium") {
      $textContainer.removeAttr("style")
      previousIsMobile = true
    } else if (previousIsMobile) {
        previousIsMobile = false
        setProportionalWidth()
      }
  })
}

export default module
