function whichTransitionEvent(){
	let transition
	const el = document.createElement('fakeelement')
	const transitions = {
		'transition':'transitionend',
		'OTransition':'oTransitionEnd',
		'MozTransition':'transitionend',
		'WebkitTransition':'webkitTransitionEnd'
	}

	Object.keys(transitions).some(function(t){
			if( el.style[t] !== undefined ){
				transition = transitions[t]
				return true
			}
			return false
	})

	return transition
}

export default whichTransitionEvent()
