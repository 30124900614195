import SearchFilter from './search-filter'

const module = {}

module.init = function () {
  const searchInstance = new SearchFilter()
  searchInstance.init({
    containerSelector: ".js-product-search",
    typeInApi: "product"
  })
}

export default module
