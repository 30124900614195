/* eslint-disable */

const module = {}
let $container
let $items
const ROW_OFFSET = 35
const getCorrectIndexInRow = function (min, max, len) {
  if (len <= max) {
    return {
      min,
      max
    }
  }
  let averageItemsInRow
  let isMaxEven
  let rest
  let obj
  function calc() {
    (averageItemsInRow = (min + max) / 2, isMaxEven = !!max % 2, rest = len % averageItemsInRow, obj = {
      min,
      max
    })
  }
  calc()
  while (rest >= 1) {
    if (isMaxEven) {
      if (rest % 2 == 0 || max === rest) {
        break
      } else {
        min--
        if (min == 0) {
          min += 2
        }
        max--
        calc()
      }
    } else if (rest % 2 || max === rest) {
        break
      } else {
        min--
        if (min == 0) {
          min += 2
        }
        max--
        calc()
      }
  }
  return obj
}
const breakpoints = {
  "small": {
    min: 1,
    max: 2,
    offset_koef: 0.75
  },
  "medium": {
    min: 1,
    max: 2,
    offset_koef: 0.75
  },
  "large": {
    min: 2,
    max: 3,
    offset_koef: 1
  },
  "xlarge": {
    min: 2,
    max: 3,
    offset_koef: 1
  },
  "xxlarge": {
    min: 3,
    max: 4,
    offset_koef: 1
  }
}
const orderBy = function (options) {
  const {min} = options
  const {max} = options
  const newIndexes = getCorrectIndexInRow(min, max, $items.length)
  $container.empty()
  wrapBy(newIndexes.min, newIndexes.max, options.offset_koef)
}
var wrapBy = function (min, max, koef) {
  let ind = 0
  let rowIndex = 0
  const len = $items.length
  const $newItems = $items.clone()
  $newItems.appendTo($container)
  while (ind < len) {
    $newItems.slice(ind, ind + max).wrapAll(`<div class="meet-bloggers__list__inner-wrap" style="top:-${  ROW_OFFSET * rowIndex * koef  }px"/>`)
    rowIndex++
    $newItems.slice(ind + max, ind + max + min).wrapAll(`<div class="meet-bloggers__list__inner-wrap"  style="top:-${  ROW_OFFSET * rowIndex * koef  }px"/>`)
    rowIndex++
    ind = ind + max + min
  }
}
const attachEvents = function () {
  $.subscribe("breakpoint_change", function (e, breakpoint) {
    orderBy(breakpoints[breakpoint])
  })
}
module.init = function () {
  $container = $(".js-meet-bloggers")
  if (!$container.length) {
    return
  }
  $items = $container.children().clone()
  orderBy(breakpoints[window.currentMQ])
  window.getCorrectIndexInRow = getCorrectIndexInRow
  attachEvents()
}
export default module
