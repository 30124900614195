const EmailSignup = function ($container) {
  const module = {}
  let $form
  let $email
  let $name
  let $showFormButton
  let mailingId
    
  const showConfirmation = function (name) {
    const nameEncoded = $("<div/>").text(name).html(); const $successBlock = $container.find(".js-email-confirmation")
    $successBlock.html($successBlock.html().replace("{Name}", nameEncoded).replace("{name}", nameEncoded).replace("{0}", nameEncoded))
    $form.addClass("form-success")
  }
  
  const showError = function () {
    $container.find(".js-email-error").show()
  }
  
  const hideError = function () {
    $container.find(".js-email-error").hide()
  }
  
  const callApi = function (mailId, data, successCallback, errorCallback) {
    $.ajax({
      url: `/api/newsletter/signup/${mailId}`,
      type: "POST",
      data,
      success () {
        if ($.isFunction(successCallback)) {
          successCallback()
        }
      },
      error () {
        if ($.isFunction(errorCallback)) {
          errorCallback()
        }
      }
    })
  }

  const showForm = function () {
    $container.removeClass("form-hidden")
  }

  const attachEvents = function () {
    $form.on("submit", function (e) {
      e.preventDefault()
      const isValid = $form.h5Validate("allValid")
      if ($form.hasClass("submitting") || !isValid) {
        return
      }
      $form.addClass("submitting")
      $form.find("button").attr("disabled", true)
      hideError()
      callApi(mailingId, {
        name: $name.val(),
        email: $email.val()
      }, function () {
        $form.removeClass("submitting")
        $form.find("button").attr("disabled", false)
        showConfirmation($name.val())
      }, function () {
        $form.removeClass("submitting")
        $form.find("button").attr("disabled", false)
        showError()
      })
    })
    $showFormButton.on("click", function (e) {
      e.preventDefault()
      showForm()
    })
  }

  module.init = function() {
    $form = $container.find(".js-email-form")
    $form.h5Validate({
      click: false
    })
    $name = $form.find(".js-name")
    $email = $form.find(".js-email")
    mailingId = $container.find(".js-mailinglistid").val()
    $showFormButton = $container.find(".js-show-form-button")
    attachEvents()
  }

  return module
}

export default {
  init: () => {
    const $body = $("body")
    if ($body.hasClass("edit-mode")) {
      $(".js-email-signup").find(".js-email-form").on("submit", function (e) {
        e.preventDefault()
      })
      return
    }

    $(".js-email-signup").each(function () {
      new EmailSignup($(this)).init()
    })
  }
}
