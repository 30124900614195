const module = {}
let $container
let $thumbs
let $bigItems
let $bubbles

const showTeaser = function ($item) {
  const ind = $item.index()
  $bigItems.removeClass("selected").eq(ind).addClass("selected")
  $bubbles.removeClass("selected").eq(ind).addClass("selected")
  $thumbs.not($item).removeClass("selected")
  $item.addClass("selected")
}

const attachEvents = function () {
  $thumbs.on("mouseenter", function () {
    showTeaser($(this))
  })
}

module.init = function () {
  $container = $(".js-teaser-tabs")
  if (!$container.length) {
    return
  }
  $thumbs = $container.find(".js-teaser-thumb")
  $bigItems = $container.find(".js-teaser-item")
  $bubbles = $container.find(".js-text")
  $thumbs.eq(0).addClass("selected")
  $bigItems.eq(0).addClass("selected")
  $bubbles.eq(0).addClass("selected")
  attachEvents()
  $bigItems.each(function (index) {
    const $clone = $thumbs.eq(index).clone()
    if (index % 2) {
      $(this).before($clone)
    } else {
      $(this).after($clone)
    }
  })
}

export default module
