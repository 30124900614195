const module = {}
let $body
let $button

const attachEvents = function () {
  $button.on("click", function (e) {
    e.preventDefault()
    $body.toggleClass("nutrition-open")
  })
}

module.init = function () {
  $body = $("body")
  $button = $(".js-nutrition-expand")
  attachEvents()
}

export default module
