import util from './util'

const module = {}
let $body
let $window
let timer

const toggleClass = function () {
  if ($window.scrollTop() > 0) {
    $body.removeClass("top")
  } else {
    if (timer) {
      clearTimeout(timer)
    }
    timer = setTimeout(function () {
      $body.addClass("top")
    }, 200)
  }
}

module.init = function () {
  $body = $("body")
  $window = $(window)
  toggleClass()
  $(document).on("scroll", util.debounce(toggleClass, 50))
}

export default module
