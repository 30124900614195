const module = {}
let $body
let $buttonOpen
let $buttonClose
let $navigationIcon
let opened = false 

const closeMenu = function () {
	opened = false
	$body.removeClass("navigation-open")
	$body.off("click.navigation")
}

const openMenu = function () {
	opened = true
	$body.addClass("navigation-open")
	$body.on("click.navigation", function (e) {
		if (opened && e.target != $buttonOpen[0] && e.target !== $buttonClose[0] && e.target !== $navigationIcon[0]) {
			if (!$(e.target).parents(".site-navigation__wrapper").length) {
				closeMenu()
			}
		}
	})
}

const attachEvents = function () {
	$buttonOpen.on("click", function (e) {
		e.preventDefault()
		openMenu()
	})
	$buttonClose.on("click", function (e) {
		e.preventDefault()
		closeMenu()
	})
	if ((/iPhone|iPad|iPod/i).test(navigator.userAgent)) {
		$(window).on("pageshow", function (e) {
			if (e.originalEvent.persisted) {
				closeMenu()
			}
		})
	}
}

module.init = function () {
  $body = $("body")
  $buttonOpen = $(".js-navigation-opener")
  $buttonClose = $(".js-navigation-close")
  $navigationIcon = $buttonOpen.find(".js-navigation-icon")
  attachEvents()
}

export default module
