import Masonry from 'masonry-layout'

const module = {}
let $container
let $list
let $masonryItems
let masonry
let $button
const ITEMS_PER_PAGE = 6
let itemsLength
let itemsCounter
let previousBreakPoint

const checkIfBottomReached = function () {
  const currentLength = $list.find(".js-product-item").length
  if (currentLength == itemsLength) {
    $container.find(".js-more-button-container").hide()
  }
}

const masonryInit = function () {
  masonry = new Masonry($list[0], {
    columnWidth: 338,
    itemSelector: ".js-product-item",
    "gutter": 24,
    transitionDuration: 0
  })
}
const masonryDestroy = function () {
  if (masonry) {
    masonry.destroy()
  }
}

const applyLayout = function () {
  $masonryItems = $list.children().clone()
  itemsLength = $masonryItems.length
  const $initialCollection = $masonryItems.slice(0, ITEMS_PER_PAGE)
  itemsCounter = ITEMS_PER_PAGE
  $list.empty().append($initialCollection.addClass("animate"))
  checkIfBottomReached()
  $container.removeClass("masonry-not-ready").addClass("masonry-ready")
  if (window.currentMQ !== "medium" && window.currentMQ !== "small") {
    masonryInit()
  }
}

const addItemsToLayout = function ($elements) {
  if (window.currentMQ !== "medium" && window.currentMQ !== "small") {
    const fragment = document.createDocumentFragment()
    const elems = []
    for (let i = 0; i < $elements.length; i+=1) {
      const elem = $elements.eq(i)[0]
      fragment.appendChild(elem)
      elems.push(elem)
    }
    $list[0].appendChild(fragment)
    $elements.addClass("animate")
    masonry.appended(elems)
  } else {
    $elements.addClass("animate")
    $list.append($elements)
  }
  checkIfBottomReached()
}

const showMoreItems = function () {
  const $items = $masonryItems.slice(itemsCounter, itemsCounter + ITEMS_PER_PAGE)
  addItemsToLayout($items)
}

const attachEvents = function () {
  applyLayout()
  $button.on("click", function (e) {
    e.preventDefault()
    showMoreItems()
  })
}

module.init = function () {
  $container = $(".js-product-related-products")
  if (!$container.length) {
    return
  }
  $list = $container.find(".js-product-list")
  $button = $container.find(".js-more-button")
  attachEvents()
  previousBreakPoint = window.currentMQ
  $.subscribe("breakpoint_change", function () {
    const previousWasMobile = previousBreakPoint === "small" || previousBreakPoint === "medium"; const currentIsMobile = window.currentMQ === "small" || window.currentMQ === "medium"
    previousBreakPoint = window.currentMQ
    if (previousWasMobile && !currentIsMobile) {
      masonryInit()
    } else if (!previousWasMobile && currentIsMobile) {
      masonryDestroy()
    }
  })
}

export default module
