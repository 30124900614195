window.youTubeIsReady = false

export default {
	isYoutubeLoading: false,
	loadYoutubePlayer() {
		if (this.isYoutubeLoading) {
			return
		}
		const youtubeApiTag = document.createElement('script')
		youtubeApiTag.src = 'https://www.youtube.com/iframe_api'
		const firstScriptTag = document.getElementsByTagName('script')[0]
		firstScriptTag.parentNode.insertBefore(youtubeApiTag, firstScriptTag)

		window.onYouTubeIframeAPIReady = () => {
			window.youTubeIsReady = true
		}
	}
}