export default function ResponsiveBackgroundImage(element) {
  const self = this
  this.element = element
  this.img = element.querySelector("img")
  this.src = ""
  if (!this.img) {
    return
  }
  this.element.style.backgroundImage = ""
  this.img.addEventListener("load", function () {
    self.update()
  })
  this.update = function () {
    const src = typeof this.img.currentSrc !== "undefined" ? this.img.currentSrc : this.img.src
    if (this.src !== src) {
      this.src = src
      this.element.style.backgroundImage = `url("${  this.src  }")`
    }
  }
  if (this.img.complete) {
    this.update()
  }
}
