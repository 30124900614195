/* eslint-disable no-unused-vars */
/* eslint-disable no-restricted-globals */

import Masonry from 'masonry-layout'
import picturefill from 'picturefill'
import Responsive from './responsive'

function Constructor() {
  const module = {}
    let $body
    let $searchContainer
    let $filterSelectWrapper
    let $staticFilterPills
    let $activeFilterWrapper
    let $activeFilterContainer
    let $filterBtn; let $searchForm
    let $searchField
    let $zeroResultContent
    let $searchResultsContainer
    let $loader
    let $removeAllLink
    let searchPageName
    let itemsLength
    let $filterModal
    let $brandSelect
    let $categorySelect
    let $window
    let $clearSelection
    let callback
    const activeFiltersFree = false
    let activeFilterIds = []
    let activeSearchTerms = []
    let productFilters = []
    let brandCode = ""
    let categoryCode = ""
    let lang
    let didScroll = false
    let masonry
    const docElem = window.document.documentElement
    let itemsRenderedCount = 0
    let resizeTimeout
    const PAGE_SIZE = 10
    const minDuration = 0.3
    const maxDuration = 0.5
		let isSearching = false

    const currentState = {
      page: 1,
      resultCount: undefined,
      numberOfPages () {
        return currentState.resultCount === 0 ? 1 : Math.ceil(currentState.resultCount / PAGE_SIZE)
      },
      lastPage () {
        return currentState.page === currentState.numberOfPages()
      }
    }

    let activeFilterWrapperExpandedHeight
    const activeFilterTemplate = "<div class=\"content-search__active-filter-button\" id=\"{0}\">{1} <a class=\"filter-container__remove-filter {3}\" data-id=\"{2}\">&times;</a></div>"
    let templateUrl
    let ajaxUrl
    let searchType
    const showModal = function () {
    $filterModal.foundation("reveal", "open")
    $(document).foundation("reflow")
  }

  function findHeightOfStaticFilterWrapper() {
    if ($filterSelectWrapper.hasClass("open")) {
      activeFilterWrapperExpandedHeight = $filterModal.outerHeight()
      $filterSelectWrapper.height(activeFilterWrapperExpandedHeight)
    }
  }

  const hideModal = function () {
    $filterModal.foundation("reveal", "close")
    findHeightOfStaticFilterWrapper()
  }

  const toggleOnlyOneResultClass = function () {
    const len = $searchResultsContainer.children().length
    if (len === 1) {
      $searchResultsContainer.addClass("one-result")
    } else {
      $searchResultsContainer.removeClass("one-result")
    }
  }

  const onScrollFn = function () {
    if (!didScroll) {
      didScroll = true
      setTimeout(function () {
        scrollPage()
      }, 60)
    }
  }

  const checkTotalRendered = function () {
    itemsRenderedCount += 1
    if (itemsRenderedCount === itemsLength) {
      toggleOnlyOneResultClass()
      window.removeEventListener("scroll", onScrollFn)
    }
  }

  const scrollPage = function () {
    $searchContainer.find(".js-product-item").each(function () {
      const $this = $(this)
      const self = this
      if (!$this.hasClass("shown") && !$this.hasClass("animate") && inViewport(this)) {
        setTimeout(function () {
          const perspY = scrollY() + getViewportH() / 2
          self.style.WebkitPerspectiveOrigin = `50% ${  perspY  }px`
          self.style.MozPerspectiveOrigin = `50% ${  perspY  }px`
          self.style.perspectiveOrigin = `50% ${  perspY  }px`
          checkTotalRendered()
          if (minDuration && maxDuration) {
            const randDuration = `${Math.random() * (maxDuration - minDuration) + minDuration  }s`
            self.style.WebkitAnimationDuration = randDuration
            self.style.MozAnimationDuration = randDuration
            self.style.animationDuration = randDuration
          }
          $this.addClass("animate")
        }, 25)
      }
    })
    didScroll = false
  }

  const resizeHandler = function () {
    function delayed() {
      scrollPage()
      resizeTimeout = null
    }
    if (resizeTimeout) {
      clearTimeout(resizeTimeout)
    }
    resizeTimeout = setTimeout(delayed, 1000)
  }

  const getViewportH = function () {
    const client = docElem.clientHeight
    const inner = window.innerHeight
    if (client < inner) return inner
    return client
  }

  const scrollY = function () {
    return window.pageYOffset || docElem.scrollTop
  }

  const getOffset = function (el) {
    let offsetTop = 0; let offsetLeft = 0
    do {
      if (!isNaN(el.offsetTop)) {
        offsetTop += el.offsetTop
      }
      if (!isNaN(el.offsetLeft)) {
        offsetLeft += el.offsetLeft
      }
    } while (el == el.offsetParent)
    return {
      top: offsetTop,
      left: offsetLeft
    }
  }

  const inViewport = function (el, hv) {
    const elH = el.offsetHeight
      const scrolled = scrollY()
      const viewed = scrolled + getViewportH()
      const elTop = getOffset(el).top
      const elBottom = elTop + elH
      const h = hv || 0

    return elTop + elH * h <= viewed && elBottom - elH * h >= scrolled
  }

  const masonryInit = function () {
    masonry = new Masonry($searchResultsContainer[0], {
      columnWidth: 338,
      itemSelector: ".js-product-item",
      "gutter": 24,
      transitionDuration: 0
    })
  }

  const applyLayout = function () {
    $searchContainer.addClass("masonry-ready")
    if (window.currentMQ !== "medium" && window.currentMQ !== "small" && searchType === "product") {
      masonryInit()
    }
    Responsive.updateBackground($searchResultsContainer.find(".js-teaser"))
    scrollPage()
  }

  const masonryDestroy = function () {
    if (masonry) {
      masonry.destroy()
    }
  }

  const addItemsToLayout = function ($elements) {
    if (window.currentMQ !== "medium" && window.currentMQ !== "small" && (searchType === "product" || searchType === "news" || searchType === "campaign")) {
      const fragment = document.createDocumentFragment()
      const elems = []

      for (let i = 0; i < $elements.length; i+=1) {
        const elem = $elements.eq(i)[0]
        fragment.appendChild(elem)
        elems.push(elem)
      }
      $searchResultsContainer[0].appendChild(fragment)
      const $items = $searchResultsContainer.children()
      itemsLength = $items.length
      if (masonry) {
        masonry.appended(elems)
      }
      Responsive.updateBackground($searchResultsContainer.find(".js-teaser"))
    } else {
      $elements.addClass("animate")
      $searchResultsContainer.append($elements)
      const $items = $searchResultsContainer.children()
      itemsLength = $items.length
      Responsive.updateBackground($searchResultsContainer.find(".js-teaser"))
    }
    scrollPage()
  }

  module.initContinousScroll = initContinousScroll

  module.stopContinousScroll = function () {
    $window.off("scroll.search")
  }

  module.init = function (config) {
    $searchContainer = $(config.containerSelector)
    lang = $searchContainer.data("lang")
    templateUrl = `{0}//{1}${  $searchContainer.data("searchUrl")  }/{2}{3}`
    searchType = $searchContainer.data("api") || config.typeInApi
    ajaxUrl = `/${  lang  }/api/search/${  searchType}`
    callback = config.callback || null
    if (!$searchContainer.length) {
      return
    }
    searchPageName = $searchContainer.data("searchWord")
    $filterModal = $searchContainer.find(".js-filter-modal")
    $body = $("body")
    $window = $(window)
    $filterSelectWrapper = $searchContainer.find(".content-search__filter-columns")
    $activeFilterWrapper = $searchContainer.find(".content-search__active-filters")
    $activeFilterContainer = $searchContainer.find(".js-active-filters")
    $staticFilterPills = $searchContainer.find(".content-search__filter__button")
    $filterBtn = $searchContainer.find(".js-search-filter-button")
    $searchForm = $searchContainer.find(".js-search-form")
    $searchField = $searchContainer.find(".js-search-field")
    $searchResultsContainer = $searchContainer.find(".js-search-results")
    $zeroResultContent = $searchContainer.find(".js-search-empty")
    $loader = $searchContainer.find(".js-search-loader")
    $removeAllLink = $searchContainer.find(".js-search-remove-all")
    $clearSelection = $searchContainer.find(".js-clear-selection")
    if ($filterBtn.length) {
      addFilterEvents()
      findHeightOfStaticFilterWrapper()
    }
    if ($searchForm.length) {
      addSearchEvent()
    }
    addEvents()
		initiateUIfromUrl()
    initModal()
    let previousBreakPoint = window.currentMQ
    $window.smartresize(function () {
      $window.off("scroll.search")
      initContinousScroll()
      findHeightOfStaticFilterWrapper()
    })
    $.subscribe("breakpoint_change", function () {
      correctPositionForMobile()
      if (searchType === "product") {
        const previousWasMobile = previousBreakPoint === "small" || previousBreakPoint === "medium"
        const currentIsMobile = window.currentMQ === "small" || window.currentMQ === "medium"
        previousBreakPoint = window.currentMQ
        if (previousWasMobile && !currentIsMobile) {
          masonryInit()
        } else if (!previousWasMobile && currentIsMobile) {
          masonryDestroy()
        }
      }
    })
  }

  function initModal() {
    const $document = $(document)
    $document.foundation({
      reveal: {
        animation: "fade"
      }
    })
    $document.on("closed.fndtn.reveal", "[data-reveal]", function () {
      $body.removeClass("search-filter-opened")
      $filterModal.removeAttr("style").removeClass("opened").appendTo($filterSelectWrapper)
      findHeightOfStaticFilterWrapper()
    })
    $document.on("opened.fndtn.reveal", "[data-reveal]", function () {
      $body.addClass("search-filter-opened")
    })
    $.subscribe("breakpoint_change", function (e, breakpoint) {
      if ($body.hasClass("search-filter-opened")) {
        if (breakpoint == "large" || breakpoint == "xlarge") {
          hideModal()
        } else {
          showModal()
        }
      }
    })
  }

  function getData() {
    return {
      PageNumber: currentState.page,
      TagIds: activeFilterIds,
      SearchTerms: activeSearchTerms
    }
  }

  function updateCurrentPage(updateOnly) {
    if (updateOnly) {
      currentState.page+=1
      return
    }
    currentState.page = 1
  }

  function updateUI(count, results) {
    const noOfActiveFilters = activeFilterIds.length + activeSearchTerms.length
    if (!activeFiltersFree) {
      if (noOfActiveFilters > 0) {
        $activeFilterWrapper.removeClass("collapsed")
        if (noOfActiveFilters > 1) {
          $removeAllLink.addClass("show")
        } else {
          $removeAllLink.removeClass("show")
        }
      } else {
        $activeFilterWrapper.addClass("collapsed")
        $activeFilterWrapper.height(0)
      }
    } else if (activeSearchTerms.length) {
        $activeFilterWrapper.removeClass("collapsed")
      } else {
        $activeFilterWrapper.addClass("collapsed")
        $activeFilterWrapper.height(0)
      }
    $("#result_count").text(count)
    const $results = $(results)
    if (searchType !== "product" && searchType !== "news" && searchType !== "campaign") {
      $results.each(function () {
        const $this = $(this)
        const isWide = $this.find(".wide").length
        let index
        if (isWide) {
          index = $this.index()
          if (index !== 0) {
            $this.addClass("middle-wide")
          }
          $this.addClass("wide-item")
        }
      })
      $searchResultsContainer.append($results).removeClass("fade")
      $searchResultsContainer.css({
        height: "auto"
      })
      $(window).trigger("scroll")
      if (window.currentMQ === "small" || window.currentMQ === "medium") {
        correctPositionForMobile()
      }
    } else {
      if (currentState.page == 1) {
        $searchResultsContainer.append($results).removeClass("fade")
        $searchResultsContainer.css({
          height: "auto"
        })
        applyLayout()
      } else {
        addItemsToLayout($results)
      }
      toggleOnlyOneResultClass()
    }
    if (count === 0) {
      $zeroResultContent.removeClass("hide")
    } else {
      $zeroResultContent.addClass("hide")
    }
    if ($.isFunction(callback)) {
      callback()
    }
  }

  function updateResults(updateOnly) {
    if (updateOnly && currentState.lastPage()) {
      return
    }
    updateCurrentPage(updateOnly)
    $.ajax({
      url: ajaxUrl,
      dataType: "json",
      beforeSend () {
        $loader.show()
        if (updateOnly) {
          return
        }
        $searchResultsContainer.removeClass("one-result")
        $searchResultsContainer.addClass("fade")
        $searchResultsContainer.css({
          height: $searchResultsContainer.height()
        })
        $searchResultsContainer.empty()
      },
      data: getData(),
      traditional: true,
      timeout: 4000
    }).done(function (data) {
      $loader.hide()
      currentState.resultCount = data.ResultCount
      updateUI(data.ResultCount, data.Html)
      initContinousScroll()
      picturefill()
			if (isSearching) {
				modifyUrlToReflectState()
				isSearching = false
			}
			if (window.location.search.includes('?q=')) {
				modifyUrlToReflectState()
			}
    })
  }

  function addEvents() {
    const $clearButtons = $removeAllLink.add($clearSelection)
    $clearButtons.on("click", function (e) {
      e.preventDefault()
      activeSearchTerms.length = 0
      activeFilterIds.length = 0
      updateResults()
      $activeFilterWrapper.addClass("collapsed")
      $removeAllLink.removeClass("show")
      window.setTimeout(function () {
        $activeFilterContainer.empty()
      }, 1000)
      $staticFilterPills.removeClass("active")
    })
    if (searchType == "product" || searchType == "news" || searchType == "campaign") {
      window.addEventListener("scroll", function () {
        onScrollFn()
      }, false)
      window.addEventListener("resize", function () {
        resizeHandler()
      }, false)
    }
  }

  function addSearchEvent() {
    $searchForm.on("submit", function (e) {
      e.preventDefault()
			isSearching = true
      const searchString = $.trim($searchField.val())
      let $tagItem
      $searchField.val("")
      if (searchString.length > 1) {
        $tagItem = checkIfSearchTermIsTag(searchString)
        if ($tagItem.length) {
          if (!$tagItem.hasClass("active")) {
            activateFilter($tagItem, $tagItem.attr("id"), $tagItem.text())
          }
          return
        }
        addFreeTextSearchTerm(searchString)
      }
    })
    $activeFilterContainer.on("click", ".remove-search", function (e) {
      e.preventDefault()
      removeSearch($(this))
    })
  }

  function addProductFilterEvents() {
    $brandSelect.on("change", function () {
      brandCode = $(this).val()
      updateResults()
    })
    $categorySelect.on("change", function () {
      categoryCode = $(this).val()
      updateResults()
    })
  }

  function addFilterEvents() {
    $filterBtn.on("click", function () {
      if (window.currentMQ == "small" || window.currentMQ == "medium") {
        showModal()
      } else {
        $body.toggleClass("search-filter-opened")
        $filterBtn.toggleClass("open")
        $filterSelectWrapper.toggleClass("open")
        if ($filterSelectWrapper.hasClass("open")) {
          findHeightOfStaticFilterWrapper()
        } else {
          $filterSelectWrapper.height(0)
        }
      }
    })
    $staticFilterPills.on("click", function (e) {
      const $this = $(this)
      e.preventDefault()
      activateFilter($this, $this.attr("id"), $this.text())
      if (window.currentMQ == "medium" || window.currentMQ == "small") {
        hideModal()
      }
    })
    $activeFilterContainer.on("click", ".remove-filter", function (e) {
      e.preventDefault()
      removeFilter($(this))
    })
  }

  function initContinousScroll() {
    const offset = $(".site-footer").height()
    $window = $(window)
    const scrollTriggerHeight = document.body.scrollHeight - (window.innerHeight + offset)
    $window.on("scroll.search", function () {
      if ($window.scrollTop() > scrollTriggerHeight) {
        updateResults(true)
        $window.off("scroll.search")
      }
    })
  }

  function setHeightOnActiveFilter() {
    const height = $activeFilterWrapper.children().eq(0).outerHeight()
    $activeFilterWrapper.height(height)
  }

  function populateArraysFromUrl() {
    const pathNoSlash = window.location.pathname.split("/").join("")
    let searches = ""
    const index = pathNoSlash.indexOf(searchPageName)
    const filters = index > -1 && pathNoSlash.substr(index + searchPageName.length).length ? pathNoSlash.substr(index + searchPageName.length).split("+") : []
    if (window.location.search && window.location.search !== "?epieditmode=True") {
      searches = window.location.search.replace("?q=", "")
    }
    activeFilterIds = filterIdsFromStrings(filters)
    activeSearchTerms = searches && window.location.search.includes('q=') ? searches.split("+") : []
    if (activeFiltersFree) {
      productFilters = filters
    }
  }

  function renderFiltersAndSearch() {
    if (activeFilterIds.length) {
      $.each(activeFilterIds, function (indx, val) {
        if (typeof val !== "undefined") {
          const $pill = $(`#${  val}`)
          $pill.addClass("active")
          const activeFilterHtml = String.format(activeFilterTemplate, `active_${  val}`, $pill.text(), val, "remove-filter")
          $activeFilterContainer.append(activeFilterHtml)
        }
      })
    }
    if (activeSearchTerms.length) {
      $.each(activeSearchTerms, function (indx, val) {
        const searchPillHtml = String.format(activeFilterTemplate, val, decodeURIComponent(val), val, "remove-search")
        $activeFilterContainer.append(searchPillHtml)
      })
    }
  }

  function initiateUIfromUrl() {
    populateArraysFromUrl()
    updateResults()
    renderFiltersAndSearch()
  }

  function modifyUrlToReflectState() {
    const rawUrlString = templateUrl
    const searchTerms = activeSearchTerms.length ? `?q=${  activeSearchTerms.join("+")}` : ""
    let filters
    let newUrl
    if (activeFiltersFree) {
      filters = getProductFilters().length ? `${getProductFilters().join("+")  }/` : ""
    } else {
      filters = filterStringFromIds().length ? `${filterStringFromIds().join("+")  }/` : ""
    }
    newUrl = String.format(rawUrlString, location.protocol, location.hostname, filters, searchTerms)
    if (!searchTerms.length && !filters.length) {
      newUrl = newUrl.replace(`${searchPageName  }/`, "")
    }
    history.replaceState({}, "", newUrl)
  }

  function filterStringFromIds() {
    const stringArr = []
    if (activeFilterIds.length) {
      $.each(activeFilterIds, function (indx, val) {
        stringArr.push($(`#${  val}`).data("urlname"))
      })
    }
    return stringArr.sort()
  }

  function filterIdsFromStrings(stringArr) {
    const idArr = []
    if (stringArr.length) {
      $.each(stringArr, function (indx, val) {
        idArr.push($(`.content-search__filter__button[data-urlname="${  val  }"]`).attr("id"))
      })
    }
    return idArr
  }

  function getProductFilters() {
    const arr = []; const brand = $brandSelect.val(); const category = $categorySelect.val()
    if (brand) {
      arr.push(brand)
    }
    if (category) {
      arr.push(category)
    }
    return arr.sort()
  }

  function setProductFiltersUI() {
    if (productFilters.length) {
      $.each(productFilters, function (index, val) {
        const $brand = $brandSelect.find(`option[value="${  val  }"]`)
        const $category = $categorySelect.find(`option[value="${  val  }"]`)
        if ($brand.length) {
          $brandSelect.val(val)
          brandCode = val
        }
        if ($category.length) {
          $categorySelect.val(val)
          categoryCode = val
        }
      })
    }
  }

  function removeElementFromArray(elem, arr) {
    let index = arr.indexOf(elem)
    if (index === -1) {
      index = arr.indexOf(elem.toString())
    }
    if (index > -1) {
      arr.splice(index, 1)
    }
  }
  function appendActiveFilter(elem) {
    if ($activeFilterWrapper.hasClass("collapsed")) {
      $activeFilterWrapper.removeClass("collapsed")
      setTimeout(function () {
        $activeFilterContainer.append(elem)
      }, 600)
      return
    }
    $activeFilterContainer.append(elem)
  }

  function deactivateFilter(id, $elem) {
    removeElementFromArray(id, activeFilterIds)
    updateResults()
    window.setTimeout(function () {
      if ($elem) {
        $elem.remove()
      } else {
        $(`#active_${  id}`).remove()
      }
    }, 1000)
    $(`#${  id}`).removeClass("active")
  }

  function activateFilter($elem, filterId, name) {
    const activeFilterHtml = String.format(activeFilterTemplate, `active_${  filterId}`, name, filterId, "remove-filter")
    if ($elem.hasClass("active")) {
      deactivateFilter(filterId)
      return
    }
    activeFilterIds.push(filterId)
    updateResults()
    $elem.addClass("active")
    appendActiveFilter(activeFilterHtml)
    setTimeout(function () {
      setHeightOnActiveFilter()
    }, 600)
  }

  function removeFilter($item) {
    const $this = $item; const $filterPill = $this.closest(".content-search__active-filter-button")
    $filterPill.addClass("removed")
    deactivateFilter($this.data("id"), $filterPill)
  }

  function addFreeTextSearchTerm(searchString) {
    const encodedSearch = encodeURIComponent(searchString)
    if (activeSearchTerms.indexOf(encodedSearch) > -1) {
      return
    }
    activeSearchTerms.push(encodedSearch)
    updateResults()
    const searchPillHtml = String.format(activeFilterTemplate, encodedSearch, searchString, encodedSearch, "remove-search")
    appendActiveFilter(searchPillHtml)
    setTimeout(function () {
      setHeightOnActiveFilter()
    }, 600)
  }

  function removeFreeTextSearchTerm(searchString, $elem) {
    removeElementFromArray(searchString, activeSearchTerms)
    updateResults()
    window.setTimeout(function () {
      $elem.remove()
    }, 1000)
  }

  function removeSearch($item) {
    const $this = $item; const $filterPill = $this.closest(".content-search__active-filter-button")
    $filterPill.addClass("removed")
    removeFreeTextSearchTerm($this.data("id"), $filterPill)
  }

  function checkIfSearchTermIsTag(name) {
    let $element = $(); const tagName = name.toLowerCase()
    $staticFilterPills.each(function () {
      const $this = $(this); const text = $this.text().toLowerCase()
      if (text == tagName) {
        $element = $element.add($this)
      }
    })
    return $element
  }

  function correctPositionForMobile() {
    $searchContainer.find(".wide-item").each(function (index) {
      const $this = $(this); const ind = $this.index(); const correctPosition = $this.data("correctPosition")
      if (index % 2 > 0) {
        if ((window.currentMQ === "small" || window.currentMQ === "medium") && ind !== 0 && !correctPosition) {
          $this.data("correctPosition", true)
          $this.prev().before($this)
        } else if (window.currentMQ !== "small" && window.currentMQ !== "medium" && correctPosition) {
          $this.next().after($this)
          $this.data("correctPosition", false)
        }
      }
    })
  }
  return module
}

export default Constructor
