import RecipeVideo from './recipe-video'
import RecipeSearch from './recipe-search'
import SpeechBubble from './speech-bubble'
import PromisePolyfill from '../vendors/promise'
import surpriseCampaign from './surprise-campaign'
import transitionEvent from '../helpers/transition-end'

if (!window.Promise) {
  window.Promise = PromisePolyfill
}

const module = {}
const layoutParam = "layout=s"

const findNodes = function () {
  module.$root = $(".js-recipe-overview")
  module.$content = $(".site-content__inner")
  module.$contentWrap = $(".site-content")
  module.htmlCache = {}
}

const appendTransitionNode = function () {
  module.$contentWrap.append("<div class=\"site-content-loader js-recipe-loader\"></div><div class=\"site-content-overlay\" id=\"page-transition\"></div>")
  module.$transitionContainer = $("#page-transition")
  module.$loader = module.$contentWrap.find(".js-recipe-loader")
}

const showLoader = function () {
  module.$loader.addClass("is-visible")
}

const hideLoader = function () {
  module.$loader.removeClass("is-visible")
}

const showPage = function (html) {
  module.$transitionContainer.one(transitionEvent, function () {
    module.$content.detach()
    module.$transitionContainer.addClass("is-animated")
    window.scrollTo(0, 0)
    RecipeSearch.stopContinousScroll()
    surpriseCampaign.init()
    SpeechBubble.init()
  })
  module.$transitionContainer.html(html).addClass("is-visible").addClass("is-full-page")
  window.isPageOverlayShown = true
}

const closePage = function () {
  window.isPageOverlayShown = false
  module.$contentWrap.append(module.$content)
  module.$transitionContainer.removeClass("is-animated").removeClass("is-full-page")
  module.$transitionContainer.one(transitionEvent, function () {
    module.$transitionContainer.removeClass("is-visible")
    RecipeSearch.initContinousScroll()
    surpriseCampaign.detach()
  })
}

const resolveUrl = function (html, resolve) {
  const $html = $(`<div>${  html  }</div>`)
  const title = $html.find("title").text()
  showPage(html)
  hideLoader()
  resolve(title)
}

const openPage = function (url) {
  const promise = new Promise(function (resolve) {
    showLoader()
    if (!module.htmlCache[url]) {
      $.ajax({
        url: `${url  }?${  layoutParam}`
      }).then(function (html) {
        resolveUrl(html, resolve)
        module.htmlCache[url] = html
      })
    } else {
      resolveUrl(module.htmlCache[url], resolve)
    }
  })
  return promise
}

const updateUrl = function (url, title) {
  const state = {
    url,
    title
  }
  window.history.pushState(state, "", url)
  window.document.title = title
}

const attachEvents = function () {
  module.$root.on("click", ".js-show-card", function (e) {
    e.preventDefault()
    const url = e.currentTarget.href
    openPage(url).then(function (title) {
      $(".js-recipe-video").each(function () {
        RecipeVideo.init()
      })
      updateUrl(url, title)
    })
  })
  $("body").on("click", ".js-close-page", function (e) {
    e.preventDefault()
    window.history.back()
  })
  window.addEventListener("popstate", function (e) {
    if (!e.state || e.state && !e.state.url) {
      closePage()
    } else {
      openPage(e.state.url)
    }
  }, false)
  window.preventReloadOnPopState = true
}

module.init = function () {
  findNodes()
  if (!module.$root.length) {
    return
  }
  appendTransitionNode()
  attachEvents()
}

export default module
