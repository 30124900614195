import bowser from '../vendors/bowser'
import Cookie from '../vendors/cookie'

const module = {}
let $container
let $body
let $overlay

const closePopup = function (e) {
  e.preventDefault()
  Cookie.set("browser-outdated", 1)
  $body.removeClass("outdated")
}

const attachEvents = function () {
  $overlay.on("click", function (e) {
    closePopup(e)
  })
  $container.find(".js-close").on("click", function (e) {
    closePopup(e)
  })
}

module.init = function () {
  if (!bowser.browser.a && !Cookie.check("browser-outdated")) {
    $body = $("body")
    $container = $(".js-outdated-browser")
    $overlay = $container.find(".js-overlay")
    $body.addClass("outdated")
    attachEvents()
  }
}

export default module
