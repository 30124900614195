/* eslint-disable consistent-return */

const module = {}
let $container
let $items
let $cinema
const loader = "<svg class=\"image-loader\" viewBox=\"0 0 38 38\" xmlns=\"http://www.w3.org/2000/svg\" stroke=\"#fff\"> <g fill=\"none\" fill-rule=\"evenodd\"> <g transform=\"translate(1 1)\" stroke-width=\"2\"> <circle stroke-opacity=\".5\" cx=\"18\" cy=\"18\" r=\"18\"/> <path d=\"M36 18c0-9.94-8.06-18-18-18\"> <animateTransform attributeName=\"transform\"type=\"rotate\"from=\"0 18 18\"to=\"360 18 18\"dur=\"1s\"repeatCount=\"indefinite\"/> </path> </g> </g> </svg> "

const hidePlayingItem = function () {
  $cinema.remove()
  $container.removeClass("playing")
  $items.removeClass("animating")
}

const expand = function ($block) {
  if ($block.hasClass("animating")) {
    hidePlayingItem()
    return
  }
  
  if ($container.hasClass("playing")) {
    hidePlayingItem()
  }
  
  const $nextItems = $block.nextAll()
  const offset = $block.offset().top
  const hasNextBlock = $nextItems.length
  let opened = false
  
  const animate = function (callback) {
    setTimeout(function () {
      $container.addClass("playing")
      $cinema.one("webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend", function () {
        if (callback) {
          callback()
        }
      }).addClass("animating")
    }, 0)
  }
  
  const renderImage = function ($image) {
    const image = $image[0].innerHTML
    $cinema.append(image)
  }

  $cinema = $(`<li class="image-gallery__cinema">${  loader  }</li>`)
  $block.addClass("animating")
  
  if (hasNextBlock) {
    $nextItems.each(function () {
      const $this = $(this)
      const offsetNext = $this.offset().top
      if (offsetNext !== offset) {
        $this.before($cinema)
        opened = true
        animate(function () {
          renderImage($block.find(".js-big-image"))
        })
        return false
      }
    })

    if (!opened) {
      $container.append($cinema)
      animate(function () {
        renderImage($block.find(".js-big-image"))
      })
    }
  } else {
    $container.append($cinema)
    animate(function () {
      renderImage($block.find(".js-big-image"))
    })
  }
}


const attachEvents = function () {
  $items.on("click", function (e) {
    e.preventDefault()
    if (window.currentMQ !== "small") {
      expand($(this))
    }
  })
}

const initGallery = function () {
  $container.owlCarousel({
    items: 1,
    loop: true,
    nav: true
  })
}

const destroyGallery = function () {
  $container.trigger("destroy.owl.carousel")
}

module.init = function () {
  $container = $(".js-image-gallery")
  if (!$container.length) {
    return
  }
  if (window.currentMQ === "small") {
    initGallery()
  }
  $items = $container.find(".js-image-gallery-item")
  $.subscribe("breakpoint_change", function (e, breakpoint) {
    if (breakpoint === "small") {
      initGallery()
    } else {
      destroyGallery()
    }
  })
  attachEvents()
}

export default module
