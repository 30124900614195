const module = {}
let $container
let $button
let $player

const attachEvents = function () {
  $button.on("click", function (e) {
    e.preventDefault()
    $container.addClass("playing")
		if (window.youTubeIsReady) {
			$player = new YT.Player("player", {
				height: "390",
				width: "640",
				videoId: $container.data('videoId'),
				events: {
					'onReady': onPlayerReady
				}
			})
		}
  })
	const onPlayerReady = () => {
		$player.playVideo()
	}
}

module.init = function () {
  $container = $(".js-video-block")
  $button = $container.find(".js-play-button")
  attachEvents()
}

export default module
