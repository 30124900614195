/* eslint-disable */

import Util from './util'

const loadYoutubeScript = function (callback) {
	let readyYT = setInterval(() => {
		if(window.youTubeIsReady) {
			callback()
			clearInterval(readyYT)
		}
	}, 200)
}

const VideoCampaignBanner = function ($container) {
  const module = {}
  let videoId
  let player
	let	autoplayEnabled
	let	playerLoop
	let	playerMute
	let	playerPlayList
  let playerId
  let $button
  let isPlaying

  const pausePlayback = function () {
    $container.removeClass("playing")
    player.pauseVideo()
    isPlaying = false
  }

  const viewPortCheck = function () {
    if (!isPlaying) {
      return
    }
    if (!Util.isInViewport($container)) {
      pausePlayback()
    }
  }

  const attachEvents = function () {
    $button.on("click", function (e) {
      e.preventDefault()
      $container.addClass("playing")
      player.playVideo()
      isPlaying = true
    })
    $(window).on("resize scroll", Util.debounce(viewPortCheck, 50))
  }

  const onStateChange = function (event) {
		if (event.data == YT.PlayerState.PLAYING) {
			$container.addClass('playing');
		}

		if (!playerLoop && (event.data == YT.PlayerState.PAUSED || event.data == YT.PlayerState.ENDED)) {
      pausePlayback()
    }
  }
  const onPlayerReady = function() {
  	if(!$container.hasClass('video-block__container')) {
			$button.click()
		}
	}

  const initPlayer = function () {
    if (typeof YT !== "undefined" && YT && YT.Player) {
      player = new YT.Player(playerId, {
        height: "390",
        width: "640",
				playerVars: {
					'modestbranding': 1,
					'controls': 0,
					'autoplay': autoplayEnabled,
					'mute': playerMute,
					'loop': playerLoop,
					'playlist': playerPlayList,
					'playsinline': 1,
					'enablejsapi': 1
				},
				autoplay: autoplayEnabled,
				loop: playerLoop,
        videoId,
				playlist: playerPlayList,
				playsinline: 1,
				enablejsapi: 1,
        events: {
					'onReady': onPlayerReady,
          "onStateChange": onStateChange
        }
      })
    }
    isPlaying = false
  }

  module.init = function () {
    if ($container.length) {
      videoId = $container.data("videoId")
			autoplayEnabled = $container.data('videoAutoplay') ? 1 : 0;
			playerLoop = 0;
			playerMute = $container.data('videoMute') ? 1 : 0;
			playerPlayList = null;

			if (autoplayEnabled) {
				playerLoop = 1;
				playerMute = 1;
				playerPlayList = videoId;
			}
      playerId = `campaign-player_${$container.attr("id")}`
      loadYoutubeScript(initPlayer)
      $button = $container.find(".js-play-button")
      attachEvents()
    }
  }

  return module
}

export default {
  init: () => {
    $('.js-video-campaign-banner').each(function () {
      new VideoCampaignBanner($(this)).init()
    })
  }
}
