import jQuery from 'jquery'
import jqueryBridget from 'jquery-bridget'
import Masonry from 'masonry-layout'

import './vendors/jquery.h5validate'
import './vendors/jquery.pubsub'
import './vendors/jquery.easing'

import './vendors/foundation/foundation'
import './vendors/foundation/foundation.reveal'

import './vendors/owl.carousel'

jqueryBridget('masonry', Masonry, jQuery)
