/* eslint-disable prefer-rest-params */

const module = {}

function extendStringFormat() {
  if (!String.format) {
    String.format = function (format) {
      const args = Array.prototype.slice.call(arguments, 1)
      return format.replace(/{(\d+)}/g, function (match, number) {
        return typeof args[number] != "undefined" ? args[number] : match
      })
    }
  }
}

module.isInViewport = function ($el) {
  const elementTop = $el.offset().top
  const elementBottom = elementTop + $el.outerHeight()
  const viewportTop = $(window).scrollTop()
  const viewportBottom = viewportTop + $(window).height()
  return elementBottom > viewportTop && elementTop < viewportBottom
}

module.debounce = (callback, wait) => {
  let timeout = null
  return (...args) => {
    const next = () => callback(...args)
    clearTimeout(timeout)
    timeout = setTimeout(next, wait)
  }
}

function fixiOS (w) {
  const ua = navigator.userAgent
  if (!((/iPhone|iPad|iPod/).test(navigator.platform) && (/OS [1-5]_[0-9_]* like Mac OS X/i).test(ua) && ua.indexOf("AppleWebKit") > -1)) {
    return
  }
  const doc = w.document
  if (!doc.querySelector) {
    return
  }
  const meta = doc.querySelector("meta[name=viewport]")
  const initialContent = meta && meta.getAttribute("content")
  const disabledZoom = `${initialContent  },maximum-scale=1`
  const enabledZoom = `${initialContent  },maximum-scale=10`
  let enabled = true
  let x
  let y
  let z
  let aig
  if (!meta) {
    return
  }
  function restoreZoom() {
    meta.setAttribute("content", enabledZoom)
    enabled = true
  }
  function disableZoom() {
    meta.setAttribute("content", disabledZoom)
    enabled = false
  }
  function checkTilt(e) {
    aig = e.accelerationIncludingGravity
    x = Math.abs(aig.x)
    y = Math.abs(aig.y)
    z = Math.abs(aig.z)
    if ((!w.orientation || w.orientation === 180) && (x > 7 || (z > 6 && y < 8 || z < 8 && y > 6) && x > 5)) {
      if (enabled) {
        disableZoom()
      }
    } else if (!enabled) {
      restoreZoom()
    }
  }
  w.addEventListener("orientationchange", restoreZoom, false)
  w.addEventListener("devicemotion", checkTilt, false)
}

(function ($, sr) {
  const debounce = function (func, threshold, execAsap) {
    let timeout
    return function debounced() {
      const obj = this
      function delayed() {
        if (!execAsap) {
          func.apply(obj, arguments)
          $.publish("smartresize_done")
        }
        timeout = null
      }
      if (timeout) {
        clearTimeout(timeout)
      } else if (execAsap) {
        func.apply(obj, arguments)
      }
      timeout = setTimeout(delayed, threshold || 100)
    }
  }
  jQuery.fn[sr] = function (fn) {
    return fn ? this.bind("resize", debounce(fn)) : this.trigger(sr)
  }
})(jQuery, "smartresize")

module.init = function () {
  extendStringFormat()
  fixiOS(window)
}

export default module
