import SearchFilter from './search-filter'

const module = {}

const init = function () {
  const searchInstance = new SearchFilter()
  searchInstance.init({
    containerSelector: '.js-recipe-search',
    typeInApi: 'recipe'
  })
  module.initContinousScroll = searchInstance.initContinousScroll
  module.stopContinousScroll = searchInstance.stopContinousScroll
}

module.init = init

export default module
