/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-shadow */

import picturefill from 'picturefill'
import ResponsiveBackgroundImage from './responsive-background-image'
import animationEndEvent from '../helpers/transition-end'

const module = {}
let lang
let $container
let $startButton
let $introBlock
let $campaign
let $introBackground
let $splitContainer
let $splitContainerFirst
let $splitContainerSecond
let $collapsedContainer
let $siteHeader
let $siteFooter
let $tiles
let $siteWrapper
let $containerWrapper
let $goToResults
let $body
let $resultsContainer
let $window
let $questionContainer
let $breadcrumbs
let answerFirst
let answerSecond
let youtubePlayer
let breadcrumbsStep = 0
let currentAnimationIndex = 0
let isCollapsedVersion
let splitInitialized = false

const startOver = function () {
  $container.removeClass("hidden-finished")
  cleanUpBeforeStartOver()
  setTimeout(function () {
    $body.animate({
      scrollTop: $resultsContainer.offset().top - $siteHeader.height()
    }, {
      easing: "easeInOutQuint",
      duration: 350,
      complete () {
        $resultsContainer.parent().append($container)
        hideResults()
        $container.removeClass("hidden")
      }
    })
  }, 0)
}

const cleanUpBeforeStartOver = function () {
  splitInitialized = false
  breadcrumbsStep = 0
  $splitContainer = $splitContainerFirst
  if (isCollapsedVersion) {
    $container.addClass("compact-version")
  }
  if (!$tiles) {
    return
  }
  $container.find(".js-hidden-pictures").remove()
  $tiles.removeClass("scaling expanded animation-finished").addClass("animated")
  $questionContainer.removeClass("over-the-top")
  $questionContainer.removeClass("visible")
  $container.find(".js-split").removeAttr("style").removeClass("visible over-the-top")
  $container.find(".js-split-image").removeAttr("style")
  $container.find(".js-split-bg").removeAttr("style")
  $container.find(".js-dragger").removeAttr("style")
  $container.find(".js-bg").removeClass("expanded").removeAttr("style")
  $container.find(".js-bg-top").removeAttr("style")
  $container.find(".js-overlay").removeAttr("style").removeClass("animate")
  $container.find(".js-top-heading").removeClass("hidden")
  $container.find(".js-bottom-heading").removeClass("hidden")
  convertToBackground($tiles.find(".js-bg"))
  $goToResults.removeClass("visible disappear-animation")
  $breadcrumbs.removeClass("visible")
  $window.off("mousewheel.campaign touchstart.campaign DOMMouseScroll.campaign resize.converted.background")
}

const animateTile = function () {
  if (currentAnimationIndex == $tiles.length) {
    return
  }
  $tiles.eq(currentAnimationIndex).addClass("animated")
  setTimeout(function () {
    currentAnimationIndex+=1
    animateTile()
  }, 70)
}

const prepareDomForCampaign = function () {
  $campaign = $container.parent()
  $campaign.after($("<div class=\"js-campaign-placeholder\"></div>"))
  $campaign.appendTo($containerWrapper)
  $containerWrapper.removeAttr("style").addClass("static")
  $siteWrapper.hide()
  $siteFooter.hide()
  $("html").css({
    "overflow-y": "scroll"
  })
}

const startCampaign = function () {
  prepareDomForCampaign()
  requestAnimationFrame(function () {
    $introBlock.addClass("hidden")
    setTimeout(function () {
      $container.removeClass("compact-version")
      $container.addClass("campaign-started")
      $introBackground.addClass("animate-bg")
      animateTile()
    }, 250)
    if (window.dataLayer) {
      window.dataLayer.push({
        "event": "startSurprisingResults"
      })
    }
  }, 0)
}

const appendCampaignBack = function () {
  $("html").removeAttr("style")
  $containerWrapper.removeClass("static")
  $siteWrapper.show()
  $siteFooter.show()
  const $placeholder = $(".js-campaign-placeholder").eq(0)
  $placeholder.before($campaign)
  $placeholder.remove()
  $window.scrollTop($campaign.offset().top - $siteHeader.height())
}

const showQuestion = function (callback) {
  $questionContainer.addClass("visible")
  if (callback) {
    callback()
  }
  const show = function () {
    if (!splitInitialized) {
      if (window.dataLayer) {
        const obj = {
          "themeType": getCurrentThemeText()
        }
        if (breadcrumbsStep == 1) {
          obj.event = "scrollToContinueOne"
        } else {
          obj.event = "scrollToContinueTwo"
        }
        window.dataLayer.push(obj)
      }
      showSplitScreen()
      setTimeout(function () {
        $questionContainer.addClass("hidden")
        hideQuestionView()
      }, 550)
    }
  }
  $questionContainer.one("touchstart.campaign", function () {
    show()
  })
  $window.one("mousewheel.campaign DOMMouseScroll.campaign", function () {
    show()
  })
}

const hideQuestionView = function () {
  $questionContainer.removeClass("visible")
  requestAnimationFrame(function () {
    $questionContainer.addClass("over-the-top").removeClass("hidden")
  })
}

const showSplitScreen = function () {
  splitInitialized = true
  const $dragContainer = $splitContainer
  const $dragElement = $dragContainer.find(".js-dragger")
  const $bg = $dragContainer.find(".js-split-bg")
  const $image = $dragContainer.find(".js-split-image")
  const $dragIcon = $dragElement.children().eq(0)
  const width = $dragElement.outerWidth()
  const $dragParents = $dragElement.parents()
  let reachedEdges = false
  let isRightSide = false
  const $overlayBottom = $bg.find(".js-overlay")
  const $overlayTop = $dragContainer.find(".js-top-bg").find(".js-overlay")
  $dragContainer.addClass("visible")

  setTimeout(function () {
    $questionContainer.off("touchstart.campaign")
    $window.off("mousewheel.campaign touchstart.campaign DOMMouseScroll.campaign")
  }, 600)

  $dragElement.off("mousedown touchstart").on("mousedown touchstart", function (e) {
    $dragElement.addClass("clicked")
    const startX = e.pageX ? e.pageX : e.originalEvent.touches[0].pageX
    const iconOffset = $dragIcon.offset().left
    const winWidth = $window.width()

    $dragParents.on("mousemove touchmove", function (e) {
      const isMobile = window.currentMQ == "small" || window.currentMQ == "medium"
      const moveX = e.pageX ? e.pageX : e.originalEvent.touches[0].pageX
      const leftValue = moveX - startX - width
      const maxKoef = isMobile ? 0.5 : 0.6
      const minKoef = isMobile ? 0.1 : 0.3
      const maxValue = winWidth * maxKoef
      const minValue = winWidth * minKoef
      const offset = iconOffset + leftValue
      if (offset < maxValue && offset > minValue) {
        reachedEdges = false
        $dragElement.removeClass("pulsing")
      } else {
        reachedEdges = true
        $dragElement.addClass("pulsing")
        if (offset > maxValue) {
          isRightSide = true
        }
      }
      $dragElement.css({
        marginLeft: leftValue
      })
      $bg.css({
        marginLeft: leftValue
      })
      $image.css({
        transform: `translate(${ -leftValue }px,0)`
      })
      if (leftValue > 0) {
        $overlayBottom.css({
          opacity: 1 - leftValue * 2.5 / winWidth
        })
      } else {
        $overlayTop.css({
          opacity: 1 + leftValue * 2.5 / winWidth
        })
      }
    })
  })

  $dragContainer.off("mouseleave mouseup touchend touchcancel").on("mouseleave mouseup touchend touchcancel", function () {
    $dragParents.off("mousemove touchmove")
    $dragElement.removeClass("clicked")
    const winWidth = $window.width()
    if (!reachedEdges) {
      $dragElement.animate({
        marginLeft: 0
      }, {
        easing: "easeOutQuint",
        duration: 700,
        step (marginLeft) {
          $bg.css({
            marginLeft
          })
          $image.css({
            transform: `translate(${  -marginLeft  }px,0)`
          })
        }
      })
    } else {
      $dragContainer.off("mouseleave mouseup touchend touchcancel")
      $dragElement.removeClass("pulsing")
      $dragElement.addClass("animate")
      setTimeout(function () {
        $dragElement.removeClass("pulsing animate")
        $overlayBottom.addClass("animate")
        $overlayTop.addClass("animate")
        if (isRightSide) {
          $overlayBottom.css({
            opacity: 0
          })
        } else {
          $overlayTop.css({
            opacity: 0
          })
        }
        let marginLeft
        const isMobile = window.currentMQ == "small" || window.currentMQ == "medium"
        if (isMobile) {
          marginLeft = isRightSide ? $window.height() * 0.8 : -$window.height() * 0.8
        } else {
          marginLeft = isRightSide ? winWidth * 0.8 : -winWidth * 0.8
        }
        $dragElement.animate({
          marginLeft: marginLeft * 1.2
        }, {
          easing: "easeOutQuint",
          duration: 700,
          step (marginLeft) {
            $bg.css({
              marginLeft
            })
            $image.css({
              transform: `translate(${  -marginLeft  }px,0)`
            })
          },
          complete () {
            let answer
            if (isRightSide) {
              answer = answerSecond
            } else {
              answer = answerFirst
            }

            if (window.dataLayer) {
              if (breadcrumbsStep == 1) {
                window.dataLayer.push({
                  "occasionType": answer.AnswerText,
                  "event": "selectOccasion"
                })
              } else {
                window.dataLayer.push({
                  "foodStyleName": answer.AnswerText,
                  "event": "selectFoodStyle"
                })
              }
            }
            if (!answer.IsLastQuestion) {
              updateBreadcrumbs(answer.AnswerText, breadcrumbsStep+=1)
              renderQuestion(answer)
              $splitContainer = $splitContainerSecond
              $splitContainer.addClass("over-the-top")
            } else {
              preloadResultPage(answer.AnswerId)
              updateBreadcrumbs(answer.AnswerText, breadcrumbsStep+=1)
              showGoToResults()
            }
          }
        })
      }, 300)
    }
  })
}

const preloadResultPage = function (answerId) {
  loadYoutubeScript()
  getQuestionByAnswerId(answerId).then(function (html) {
    initResults(html)
  })
}

const initResults = function (html) {
  $resultsContainer = $(html)
  $resultsContainer.addClass("hidden positioned")
  $container.after($resultsContainer)
  picturefill()
  $resultsContainer.on("click", ".js-surprise-video", function (e) {
    e.preventDefault()
    playResultsVideo($(e.currentTarget))
  })
  $resultsContainer.on("click", ".surprise-campaign__results__tips__list a", function (e) {
    if (window.dataLayer) {
      window.dataLayer.push({
        "event": "relatedLinks",
        "linkURL": e.currentTarget.href
      })
    }
  })
  $resultsContainer.on("click", ".surprise-campaign__recipes__list a", function (e) {
    const $link = $(e.currentTarget)
    const title = $link.find(".surprise-campaign__recipes__name").text().trim()
    if (window.dataLayer) {
      window.dataLayer.push({
        "event": "relatedRecipe",
        "recipeName": title
      })
    }
  })
  convertToBackground($resultsContainer.find(".js-tips-bg"))
  convertToBackground($resultsContainer.find(".js-video-placeholder"))
}

const showResults = function () {
  const show = function () {
    $resultsContainer.removeAttr("style")
    $container.detach()
    $resultsContainer.removeClass("positioned")
  }
  $resultsContainer.css({
    top: $container.offset().top
  })
  if ($container.is(":hidden")) {
    show()
  } else {
    $container.one(animationEndEvent, function () {
      show()
    })
  }
  $container.addClass("hidden")
  $resultsContainer.removeClass("hidden")
}

const stopVideo = function () {
  if (youtubePlayer) {
    youtubePlayer.stopVideo()
  }
}

const hideResults = function () {
  $resultsContainer.one(animationEndEvent, function () {
    $resultsContainer.remove()
  })
  $resultsContainer.addClass("hidden")
}

const showGoToResults = function () {
  $splitContainer.find(".js-top-heading").addClass("hidden")
  $splitContainer.find(".js-bottom-heading").addClass("hidden")
  $goToResults.addClass("visible")
}

const getQuestionByAnswerId = function (answerId) {
  return $.ajax({
    cache: true,
    url: `/api/${  lang  }/surprising/answer?answerId=${  answerId}`
  })
}

const getFinishedStatus = function () {
  return $.ajax({
    url: `/api/${  lang  }/surprising/IsFinished`
  })
}

const getFlowState = function () {
  return $.ajax({
    url: `/api/${  lang  }/surprising/StartOver?url=${  window.location.href}`
  })
}

const getUserResults = function () {
  return $.ajax({
    url: `/api/${  lang  }/surprising/GetResult`
  })
}

const generatePicture = function (data) {
  let picture = "<picture style=\"position: absolute;visibility: hidden\" class=\"js-hidden-pictures\">"
  const breakpoints = []
  for (const prop in data.ImageSources) {
    if (data.ImageSources.hasOwnProperty(prop)) {
      breakpoints.push(`<source srcset="${  data.ImageSources[prop]  }" media="(min-width: ${  prop  }px)">`)
    }
  }
  picture += breakpoints.reverse().join("")
  picture += `<img srcset="${  data.ImageUrl  }">`
  return `${picture  }</picture>`
}

const renderSplitAnswers = function (data) {
  [answerFirst, answerSecond] = data

  $splitContainer.find(".js-top-heading").html(answerFirst.DescriptionText)
  $splitContainer.find(".js-bottom-heading").html(answerSecond.DescriptionText)
  $splitContainer.find(".js-top-bg").append(generatePicture(answerFirst))
  $splitContainer.find(".js-split-image").append(generatePicture(answerSecond))
  picturefill()
  convertToBackground($splitContainer.find(".js-top-bg,.js-split-image"))
}

const renderFirstQuestion = function (data) {
  $questionContainer.find(".js-subheading").html(data.heading)
  $questionContainer.find(".js-question-text").html(data.description)
}

const renderQuestion = function (data) {
  splitInitialized = false
  $questionContainer.find(".js-subheading").html(data.QuestionText)
  $splitContainer.find(".js-top-heading").addClass("hidden")
  $splitContainer.find(".js-bottom-heading").addClass("hidden")
  $questionContainer.find(".js-question-text").html(data.QuestionDescription)
  showQuestion()
  getQuestionByAnswerId(data.AnswerId).then(function (data) {
    renderSplitAnswers(data)
  })
}

const loadYoutubeScript = function () {
	if(window.youTubeIsReady) {
		youtubeReady = true
	}
}

const showTile = function (element) {
  const id = $(element).data("answerId")
  getQuestionByAnswerId(id).then(function (data) {
    renderSplitAnswers(data)
  })
  const targetWidth = $window.width()
  const siteHeaderHeight = $siteHeader.height()
  const targetHeight = $container.height()
  const $element = $(element)
  const elementWidth = $element.outerWidth(true)
  const elementHeight = $element.outerHeight()
  const offsetLeft = $element.offset().left
  const offsetTop = $element.offset().top
  const scaleX = targetWidth / elementWidth
  const scaleY = targetHeight / elementHeight
  const $img = $element.find(".js-bg")
  const isMobile = window.currentMQ == "small" || window.currentMQ == "medium"
  $tiles.addClass("animation-finished")
  $tiles.removeClass("animated")
  if (isMobile) {
    $element.css({
      transform: `translateY(${  offsetTop - siteHeaderHeight  }px)`
    })
  } else {
    $element.css({
      transform: `translateX(${  offsetLeft  }px)`
    })
  }
  const $placeholder = $("<div></div>").css({
    width: elementWidth,
    height: elementHeight
  })
  $element.addClass("scaling")
  $element.before($placeholder)
  setTimeout(function () {
    $element.addClass("expand-animation")
    requestAnimationFrame(function () {
      let elementTransform
      let imageTransform
      if (isMobile) {
        elementTransform = `translateY(0) scale(${  scaleX  },${  scaleY  })`
        imageTransform = `translate(-50%, -50%) scale(${  1 / scaleX  },${  1 / scaleY  })`
      } else {
        elementTransform = `translateX(0) scale(${  scaleX  },${  scaleY  })`
        imageTransform = `translate(0, 0) scale(${  1 / scaleX  },${  1 / scaleY  })`
      }
      $img.css({
        transform: imageTransform
      })
      $element.css({
        transform: elementTransform
      })
      showBreadcrumbs(element)
      $img.one(animationEndEvent, function () {
        $element.addClass("expanded")
        $element.removeClass("expand-animation")
        $img.addClass("expanded")
        $element.removeAttr("style")
        let transform
        if (isMobile) {
          transform = "translate(-50%, -50%)"
        } else {
          transform = "translate(0, 0)"
        }
        $img.css({
          "transform": transform
        })
        showQuestion(function () {
          $placeholder.remove()
        })
      })
    })
  }, 50)
}

const showBreadcrumbs = function (element) {
  const name = element.querySelector(".surprise-campaign__tile__link").textContent
  breadcrumbsStep+=1
  $breadcrumbs.find("li").eq(0).text(name)
  $breadcrumbs.addClass("visible")
}

const updateBreadcrumbs = function (name, stepNumber) {
  $breadcrumbs.find("li").eq(stepNumber).text(name).addClass("active")
}

const getCurrentThemeText = function () {
  return $breadcrumbs.find("li.active").last().text().trim()
}

const getSelectionPath = function () {
  let path = ""
  $breadcrumbs.find("li.active").each(function () {
    path += (path ? " - " : "") + $(this).text().trim()
  })
  return path
}

const playResultsVideo = function ($button) {
  $button.addClass("animated")
  $resultsContainer.find(".surprise-campaign__results__video__subheading").addClass("animated")
  $resultsContainer.find(".surprise-campaign__results__video__heading").addClass("animated")
  $("#suprise-video").show()
  const videoId = $button.data("videoId")
  $button.one(animationEndEvent, function () {
    $resultsContainer.find(".surprise-campaign__results__video__inner").hide()
    youtubePlayer = new YT.Player("suprise-video", {
      playerVars: {
        "autoplay": 1,
        "rel": 0,
        "list": videoId,
        "modestbranding": 1,
        "showinfo": 0
      },
      videoId
    })
  })
}

const convertToBackground = function ($elements) {
  $elements.each(function (index, element) {
    const responsiveImg = new ResponsiveBackgroundImage(element)
    $window.on("resize.converted.background", function () {
      responsiveImg.update()
    })
  })
}

const collapseCampaign = function () {
  const $userResultContainer = $(".surprise-campaign__results-wrap")
  stopVideo()
  $userResultContainer.one(animationEndEvent, function () {
    requestAnimationFrame(function () {
      $collapsedContainer.addClass("visible")
      $siteWrapper.delay(300).fadeIn(300, function () {
        $containerWrapper.hide()
        $siteFooter.show()
        cleanUpBeforeStartOver()
        $userResultContainer.removeClass("collapse")
      })
    })
  })
  $userResultContainer.addClass("collapse")
}

const closeCampaign = function () {
  stopVideo()
  if ($introBackground.is(":hidden")) {
    appendCampaignBack()
  } else {
    $introBackground.one(animationEndEvent, function () {
      appendCampaignBack()
    })
  }
  $introBlock.removeClass("hidden")
  $introBackground.removeClass("animate-bg")
  cleanUpBeforeStartOver()
  setTimeout(function () {
    $container.removeClass("campaign-started")
  }, 150)
}

const showCampaign = function () {
  if ($container.length) {
    $collapsedContainer.one(animationEndEvent, function () {
      if ($resultsContainer) {
        $window.scrollTop(0)
        prepareDomForCampaign()
        showResults()
        $collapsedContainer.removeClass("visible expand")
        $window.scrollTop(0)
      } else {
        $window.scrollTop(0)
        prepareDomForCampaign()
        getUserResults().then(function (html) {
          initResults(html)
          $collapsedContainer.one(animationEndEvent, function () {
            showResults()
          })
          $collapsedContainer.removeClass("visible expand")
        })
      }
    })
    $collapsedContainer.addClass("expand")
  } else {
    const $contWrap = $("<div/>")
    $container = $("<div class=\"surprise-frame\"></div>")
    $contWrap.append($container)
    $siteWrapper.children().eq(0).children().eq(0).before($contWrap)
    $collapsedContainer.one(animationEndEvent, function () {
      prepareDomForCampaign()
      $window.scrollTop(0)
      getUserResults().then(function (html) {
        initResults(html)
        $collapsedContainer.one(animationEndEvent, function () {
          showResults()
        })
        $collapsedContainer.removeClass("visible expand")
      })
    })
    $collapsedContainer.addClass("expand")
  }
}

const resultsEvents = function () {
  $("body").on("click", ".js-start-over", function (e) {
    e.preventDefault()
    const $link = $(e.currentTarget)
    getFlowState().then(function (data) {
      if (window.dataLayer) {
        window.dataLayer.push({
          "event": "startOver",
          "selectionPath": $link.data("selectionPath")
        })
      }
      if (!data.IsNeedRedirect) {
        startOver()
      } else {
        window.location = `${data.RedirectUrl  }#suprise-campaign`
      }
    })
  })
  $("body").on("click", ".js-close-campaign-results", function (e) {
    e.preventDefault()
    const $link = $(e.currentTarget)
    collapseCampaign()
    if (window.dataLayer) {
      window.dataLayer.push({
        "event": "closeResults",
        "selectionPath": $link.data("selectionPath")
      })
    }
  })
}

const attachEvents = function () {
  $startButton.on("click", function (e) {
    e.preventDefault()
    startCampaign()
  })
  $tiles.on("click", function (e) {
    e.preventDefault()
    const $currentTile = $(e.currentTarget)
    showTile(e.currentTarget)
    renderFirstQuestion({
      heading: $currentTile.data("questionText"),
      description: $currentTile.data("questionDescription")
    })
    if (window.dataLayer) {
      window.dataLayer.push({
        "event": "selectTheme",
        "themeType": $currentTile.find(".surprise-campaign__tile__link").text().trim()
      })
    }
  })
  $container.on("click", ".js-see-results", function (e) {
    e.preventDefault()
    if ($resultsContainer) {
      $goToResults.addClass("disappear-animation")
      showResults()
      if (window.dataLayer) {
        window.dataLayer.push({
          "selectionPath": getSelectionPath(),
          "event": "seeYourResults"
        })
      }
    }
  })
  $container.parent().on("click", ".js-close-campaign", function (e) {
    e.preventDefault()
    if (window.dataLayer) {
      window.dataLayer.push({
        "event": "closeResults",
        "selectionPath": getSelectionPath()
      })
    }
    closeCampaign()
  })
}

const collapseEvents = function () {
  $(".js-campaign-collapsed-link").on("click", function (e) {
    e.preventDefault()
    showCampaign()
    if (window.dataLayer) {
      window.dataLayer.push({
        "event": "openResults",
        "pageURL": window.location.href
      })
    }
  })
}

const attachPopStateReload = function () {
  window.addEventListener("popstate", function (e) {
    if (!window.preventReloadOnPopState) {
      window.location.reload()
    }
  }, false)
}

const init = function () {
  $container = $(".js-surprise-campaign")
  attachPopStateReload()
  if (!$container.length) {
    return
  }
  $collapsedContainer = $(".js-campaign-collapsed")
  lang = $("html").attr("lang")
  $containerWrapper = $("<div class=\"surprise-campaign-wrapper\"></div>")
  $siteWrapper = $(".site-content")
  $siteFooter = $(".site-footer")
  $siteHeader = $(".site-header")
  $siteWrapper.after($containerWrapper)
  $window = $(window)
  $body = $("html,body")
  collapseEvents()
  resultsEvents()
  getFinishedStatus().then(function (data) {
    if (data) {
      $collapsedContainer.addClass("visible")
    }
  })
  $startButton = $container.find(".js-surprise-campaign-start")
  $introBlock = $container.find(".js-intro")
  $introBackground = $container.find(".js-intro-bg")
  $tiles = $container.find(".js-tile")
  isCollapsedVersion = $container.hasClass("compact-version")
  convertToBackground($tiles.find(".js-bg"))
  $container.find(".js-split").clone().appendTo($container)
  $splitContainerFirst = $container.find(".js-split").eq(0)
  $splitContainerSecond = $container.find(".js-split").eq(1)
  $splitContainer = $splitContainerFirst
  $questionContainer = $container.find(".js-question")
  $goToResults = $container.find(".js-go-to-results")
  $breadcrumbs = $container.find(".js-breadcrumbs")
  attachEvents()
}

const detach = function () {
  const $campaingWrapper = $(".surprise-campaign-wrapper")
  if (!$campaingWrapper.length) {
    return
  }
  $campaingWrapper.detach()
  $collapsedContainer.removeClass("visible")
}

module.detach = detach
module.init = init

export default module
