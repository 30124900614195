/* eslint-disable prefer-destructuring */
/* eslint-disable no-alert */

const module = {}
let $form
let language

const signUp = function () {
  const uploadURL = `/api/${  language  }/competition/upload`
  const submitURL = `/api/${  language  }/competition/register`
  let defaultText
  let Attachments = []

  $form.find("input[type=\"file\"]").on("change", function () {
    const el = $(this)
    const extension = el.val()
    const fileName = el.val().split("\\").pop()
    switch (extension.substring(extension.lastIndexOf(".") + 1).toLowerCase()) {
      case "gif":
      case "jpg":
      case "jpeg":
      case "png":
        el.addClass("file-attached")
        el.parent().addClass("file-attached")
        defaultText = el.next("p").html()
        el.next("p").text(fileName)
        el.parents(".form-row").next().removeClass("hidden")
        break
      default:
        alert("Uploaded file not an image")
        break
    }
  })

  $form.find(".js-clear-upload").on("click", function () {
    const btnParent = $(this).parent()
    btnParent.find("p").html(defaultText)
    btnParent.find("input[type=\"file\"]").val("").removeClass("file-attached")
    btnParent.removeClass("file-attached")
    $(this).parents(".form-row").next().addClass("hidden")
  })

  $form.on("submit", function (e) {
    e.preventDefault()
    const isValid = $form.h5Validate("allValid")
    const data = {}
    const formValuesArray = $form.serializeArray()
    for (let i = 0, len = formValuesArray.length; i < len; i+=1) {
      data[formValuesArray[i].name] = formValuesArray[i].value
    }
    if (!isValid) {
      return
    }
    const formData = new FormData()
    formData.append("action", "uploadImages")
    $.each($("input[type=file]"), function (i, obj) {
      $.each(obj.files, function (j, file) {
        formData.append(`photo[${  j  }]`, file)
      })
    })
    $form.parent().append("<div class=\"loading-mask\"><div class=\"sk-folding-cube\"><div class=\"sk-cube1 sk-cube\"></div><div class=\"sk-cube2 sk-cube\"></div><div class=\"sk-cube4 sk-cube\"></div><div class=\"sk-cube3 sk-cube\"></div></div></div>")
    $.ajax({
      url: uploadURL,
      type: "POST",
      data: formData,
      contentType: false,
      processData: false,
      success (response) {
        Attachments = []
        Attachments.push(response)
        data.Attachments = Attachments[0]
        data.BlockID = $form.parents(".new-competition-signup").data("id")
        $.ajax({
          url: submitURL,
          type: "POST",
          data: JSON.stringify(data),
          contentType: "application/json",
          success () {
            const $block = $form.parent()
            $block.find(".loading-mask").remove()
            $form.remove()
            $block.find("h3:not(.signup-confirmation)").hide()
            $block.find(".signup-confirmation").removeClass("hidden")
          },
          error () {
            const $block = $form.parent()
            $block.find(".loading-mask").remove()
            $form.remove()
            $block.find("h3:not(.signup-error)").hide()
            $block.find(".signup-error").removeClass("hidden")
          }
        })
      },
      error () {
        console.log("error")
      }
    })
  })
}

const attachEvents = function () {
  signUp()
}

module.init = function () {
  $form = $(".js-competition-block")

  if (!$form.length) {
    return
  }

  language = $(".taco-wrapper-page").data("language")

  if (language) {
    attachEvents()
  }
}

export default module
