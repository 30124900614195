const module = {}
let $squareBox

const calcArrowSize = function () {
  $squareBox.each(function () {
    const $this = $(this)
    const width = $this.width()
    const height = $this.outerHeight()
    const initialized = $this.find(".js-square-bg").length
    const $arrow = !initialized ? $("<span class=\"site-hero__decor js-square-bg\"></span>") : $this.find(".js-square-bg")
    const arrowSize = (height * 2 + width) / 2
    $arrow.css({
      "border-width": Math.ceil(arrowSize)
    })
    if (!initialized) {
      $arrow.appendTo($this)
    }
  })
}

module.init = function () {
  $squareBox = $(".js-square")
  calcArrowSize()
  $(window).on("resize", calcArrowSize)
}

export default module
