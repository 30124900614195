/* eslint-disable */

const module = {}
let $container
let $items
let $cinema
let player
const loader = "<svg class=\"video-loader\" viewBox=\"0 0 38 38\" xmlns=\"http://www.w3.org/2000/svg\" stroke=\"#fff\"> <g fill=\"none\" fill-rule=\"evenodd\"> <g transform=\"translate(1 1)\" stroke-width=\"2\"> <circle stroke-opacity=\".5\" cx=\"18\" cy=\"18\" r=\"18\"/> <path d=\"M36 18c0-9.94-8.06-18-18-18\"> <animateTransform attributeName=\"transform\"type=\"rotate\"from=\"0 18 18\"to=\"360 18 18\"dur=\"1s\"repeatCount=\"indefinite\"/> </path> </g> </g> </svg> "

const expand = function ($block) {
  const hidePlayingItem = function () {
    $cinema.remove()
    $container.removeClass("playing")
    $items.removeClass("animating")
  }

  if ($block.hasClass("animating")) {
    hidePlayingItem()
    return
  }
  if ($container.hasClass("playing")) {
    hidePlayingItem()
  }

  const $nextItems = $block.nextAll()
  const offset = $block.offset().top
  const hasNextBlock = $nextItems.length
  let opened = false

  const animate = function (callback) {
    setTimeout(function () {
      $container.addClass("playing")
      $cinema.one("webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend", function () {
        if (callback) {
          callback()
        }
      }).addClass("animating")
    }, 0)
  }

  const playVideo = function (id) {
    if (window.youTubeIsReady) {
      if (player) {
        player.destroy()
      }
      player = new YT.Player("gallery-player", {
        height: "390",
        width: "640",
        videoId: id,
				events: {
					'onReady': onPlayerReady
				}
      })
    }
  }

	const onPlayerReady = () => {
		player.playVideo()
	}

  $cinema = $(`<li class="video-gallery__cinema"><div id="gallery-player">${  loader  }</div></li>`)
  $block.addClass("animating")
  if (hasNextBlock) {
    $nextItems.each(function () {
      const $this = $(this)
      const offsetNext = $this.offset().top
      if (offsetNext !== offset) {
        $this.before($cinema)
        opened = true
        animate(function () {
          playVideo($block.data("videoId"))
        })
        return false
      }
    })
    if (!opened) {
      $container.append($cinema)
      animate(function () {
        playVideo($block.data("videoId"))
      })
    }
  } else {
    $container.append($cinema)
    animate(function () {
      playVideo($block.data("videoId"))
    })
  }
}

const appendIframes = function () {
  $items.each(function () {
    const $this = $(this)
    const id = $this.data("videoId")
    $this.find(".video-gallery__item__iframe-wrap").append(`<iframe class="mobile-iframe" src="//www.youtube-nocookie.com/embed/${  id  }?showinfo=0">`)
  })
}

const attachEvents = function () {
  $items.each(function () {
    const $this = $(this)
    const $button = $this.find(".js-play-button")
    $button.on("click", function (e) {
      e.preventDefault()
      expand($this)
    })
  })
}

const init = function () {
  $container = $(".js-video-gallery")
  if (!$container.length) {
    return
  }
  $items = $container.find(".js-video-gallery-item")
  if (window.currentMQ == "small") {
    appendIframes()
  }
  attachEvents()
}

module.init = init

export default module
