const module = {}
let $container
const PAGE_SIZE = 10

const showMore = function ($el, $button) {
  const $items = $el.find(".js-more-item")
  const $visibleItems = $items.not(".visible")
  $visibleItems.slice(0, PAGE_SIZE).addClass("visible")
  if ($items.filter(".visible").length === $items.length) {
    $button.addClass("hidden")
  }
}

const attachEvents = function () {
  $container.each(function () {
    const $this = $(this)
    const $button = $this.find(".js-more-button")
    const $items = $this.find(".js-more-item")
    $items.slice(0, 5).addClass("visible")
    $items.each(function () {
      const $self = $(this)
      const isWide = $self.find(".wide").length
      let index
      if (isWide) {
        index = $self.index()
        if (index !== 0) {
          $self.addClass("middle-wide")
        }
        $self.addClass("wide-item")
      }
    })
    $button.on("click", function (e) {
      e.preventDefault()
      showMore($this, $(this))
    })
  })
}

module.init = function () {
  $container = $(".js-more-items")
  attachEvents()
}

export default module
